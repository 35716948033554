import { useState } from "react";
import {
  Box,
  Text,
  Button,
  ActionMenu,
  ActionList,
  ProgressBar,
  Spinner,
} from "@primer/react";
import { Dialog } from "@primer/react/experimental";
import {
  HistoryIcon,
  CreditCardIcon,
  PlusIcon,
  FiscalHostIcon,
} from "@primer/octicons-react";
import { DashboardLayout } from "../components/DashboardLayout";
import { formatDistanceToNow } from "date-fns";
import { useWallet } from "../hooks/useWallet";

interface Transaction {
  id: string;
  amount: number;
  type: "credit_card" | "cash" | "usage";
  status: "completed" | "pending" | "failed";
  created_at: Date;
}

export default function Usage() {
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { walletData, loading: walletLoading } = useWallet();

  const usageData = {
    freeLimit: walletData?.free_usage_limit || 1000,
    used: walletData?.free_usage || 0,
    tokens: {
      available: walletData?.available_tokens || 0,
      used: walletData?.token_usage || 0,
    },
    wallet: {
      balance: walletData?.amount || 0,
      lastUpdated: walletData?.updated_at || new Date(),
    },
  };

  const calculatePercentage = (used: number, total: number) => {
    if (total === 0) return 0;
    return (used / total) * 100;
  };

  return (
    <DashboardLayout data-oid="xbezqdg">
      <Box sx={{ p: 4 }} data-oid=":59:8xu">
        <Text
          as="h2"
          sx={{ fontSize: 4, fontWeight: "bold", mb: 4 }}
          data-oid="_exdh.0"
        >
          Usage & Billing
        </Text>

        {walletLoading ? (
          <Box
            sx={{ display: "flex", justifyContent: "center", p: 4 }}
            data-oid="whd:8qi"
          >
            <Spinner size="large" data-oid="gjg6gbh" />
          </Box>
        ) : (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr",
              gap: 4,
              maxWidth: "1200px",
            }}
            data-oid="nxp070u"
          >
            {/* Wallet Section */}
            <Box data-oid="1h8extr">
              <Box
                sx={{
                  p: 4,
                  border: "1px solid",
                  borderColor: "border.default",
                  borderRadius: 2,
                  bg: "canvas.subtle",
                }}
                data-oid="tg34ia9"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 3,
                  }}
                  data-oid="npk96lo"
                >
                  <CreditCardIcon size={16} data-oid="xcc_.o1" />
                  <Text
                    sx={{ fontSize: 2, fontWeight: "bold" }}
                    data-oid="ub8.ah4"
                  >
                    Wallet
                  </Text>
                </Box>

                <Box sx={{ mb: 4 }} data-oid="nbfqazo">
                  <Text
                    sx={{
                      fontSize: 4,
                      fontWeight: "bold",
                      display: "block",
                      mb: 2,
                    }}
                    data-oid="ls11s9f"
                  >
                    ${usageData.wallet.balance.toFixed(2)}
                  </Text>
                  <Box
                    sx={{
                      pt: 2,
                      borderTop: "1px solid",
                      borderColor: "border.muted",
                    }}
                    data-oid="nzi6zsj"
                  >
                    <Text
                      sx={{
                        fontSize: "12px",
                        color: "fg.muted",
                      }}
                      data-oid="l14c.ak"
                    >
                      Last updated{" "}
                      {formatDistanceToNow(usageData.wallet.lastUpdated)} ago
                    </Text>
                  </Box>
                </Box>

                <Box
                  sx={{ display: "flex", gap: 2, flexDirection: "column" }}
                  data-oid="i0::wze"
                >
                  <Button
                    onClick={() => setIsHistoryModalOpen(true)}
                    variant="default"
                    leadingVisual={HistoryIcon}
                    sx={{ width: "100%" }}
                    data-oid="sqe65h5"
                  >
                    Transaction History
                  </Button>

                  <ActionMenu data-oid="-ye5s7j">
                    <ActionMenu.Anchor data-oid="46ysh-w">
                      <Button
                        variant="primary"
                        leadingVisual={PlusIcon}
                        sx={{ width: "100%" }}
                        data-oid="u49yl7f"
                      >
                        Top Up Wallet
                      </Button>
                    </ActionMenu.Anchor>
                    <ActionMenu.Overlay data-oid="sqs97-d">
                      <ActionList data-oid="y7iiq._">
                        <ActionList.Item data-oid="_c:vz5y">
                          <ActionList.LeadingVisual data-oid="tjj4xme">
                            <CreditCardIcon data-oid="k-5:5pe" />
                          </ActionList.LeadingVisual>
                          Pay with Credit Card
                        </ActionList.Item>
                        <ActionList.Item data-oid="vem03g-">
                          <ActionList.LeadingVisual data-oid="u9hu1az">
                            <FiscalHostIcon data-oid="o0e1ne7" />
                          </ActionList.LeadingVisual>
                          Pay with Cash
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </Box>
              </Box>
            </Box>

            {/* Usage Section */}
            <Box data-oid="lc80-ah">
              {/* Free Usage Section */}
              <Box
                sx={{
                  p: 4,
                  border: "1px solid",
                  borderColor: "border.default",
                  borderRadius: 2,
                  mb: 4,
                }}
                data-oid="q2wshgq"
              >
                <Text
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 3 }}
                  data-oid="8b8pxbv"
                >
                  Free Usage
                </Text>

                <Box sx={{ mb: 2 }} data-oid="xldf1le">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                    data-oid="-11q-1q"
                  >
                    <Text data-oid="1-jkvs_">
                      Remaining {usageData.freeLimit - usageData.used} of{" "}
                      {usageData.freeLimit}
                    </Text>
                    <Text data-oid="mft.-k0">
                      {calculatePercentage(
                        usageData.freeLimit - usageData.used,
                        usageData.freeLimit,
                      ).toFixed(1)}
                      %
                    </Text>
                  </Box>
                  <ProgressBar
                    progress={calculatePercentage(
                      usageData.freeLimit - usageData.used,
                      usageData.freeLimit,
                    )}
                    sx={{
                      bg: "border.default",
                      "> div": {
                        bg: "success.emphasis",
                      },
                    }}
                    data-oid="u196ss."
                  />

                  <Text
                    sx={{
                      fontSize: 1,
                      color: "fg.muted",
                      mt: 2,
                    }}
                    data-oid="c7wuf:a"
                  >
                    {usageData.used} usages consumed
                  </Text>
                </Box>
              </Box>

              {/* Tokens Section */}
              <Box
                sx={{
                  p: 4,
                  border: "1px solid",
                  borderColor: "border.default",
                  borderRadius: 2,
                }}
                data-oid="bmzr6hr"
              >
                <Text
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 3 }}
                  data-oid="09r:pyq"
                >
                  Tokens
                </Text>

                <Box sx={{ mb: 2 }} data-oid="oqrr59l">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 2,
                    }}
                    data-oid="sf.s29q"
                  >
                    <Text data-oid=":gb04o:">
                      Remaining {usageData.tokens.available} of{" "}
                      {usageData.tokens.used + usageData.tokens.available}
                    </Text>
                    <Text data-oid="zwfyim8">
                      {calculatePercentage(
                        usageData.tokens.available,
                        usageData.tokens.used + usageData.tokens.available,
                      ).toFixed(1)}
                      %
                    </Text>
                  </Box>
                  <ProgressBar
                    progress={calculatePercentage(
                      usageData.tokens.available,
                      usageData.tokens.used + usageData.tokens.available,
                    )}
                    sx={{
                      bg: "border.default",
                      "> div": {
                        bg: "accent.emphasis",
                      },
                    }}
                    data-oid="c4k1u_l"
                  />

                  <Text
                    sx={{
                      fontSize: 1,
                      color: "fg.muted",
                      mt: 2,
                    }}
                    data-oid="2revqz1"
                  >
                    {usageData.tokens.used} tokens consumed
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* Transaction History Modal */}
        {isHistoryModalOpen && (
          <Dialog
            title="Transaction History"
            onClose={() => setIsHistoryModalOpen(false)}
            width="xlarge"
            sx={{
              maxWidth: "50vw !important",
              margin: "0 auto",
            }}
            data-oid="8qe997i"
          >
            <Box sx={{ p: 3 }} data-oid="54kmtg8">
              {loading ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    py: 6,
                    gap: 3,
                  }}
                  data-oid="0gxajzi"
                >
                  <Spinner size="large" data-oid="owpdzi:" />
                  <Text sx={{ color: "fg.muted" }} data-oid="67u7xfj">
                    Loading transactions...
                  </Text>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                  data-oid="9czk1m6"
                >
                  {/* Empty State */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      py: 6,
                      gap: 2,
                    }}
                    data-oid="i3ldsgl"
                  >
                    <Text
                      sx={{ fontSize: 2, fontWeight: "bold" }}
                      data-oid="0v29g-7"
                    >
                      No Transactions Yet
                    </Text>
                    <Text
                      sx={{ color: "fg.muted", textAlign: "center" }}
                      data-oid="lkckf9g"
                    >
                      Your transaction history will appear here once you start
                      using the service.
                    </Text>
                  </Box>

                  {/* Transaction List - Currently hidden, will be shown when we have data */}
                  <Box sx={{ display: "none" }} data-oid="olr-emj">
                    {/* Transaction Item Template */}
                    <Box
                      sx={{
                        p: 3,
                        borderBottom: "1px solid",
                        borderColor: "border.default",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      data-oid="eaprq_s"
                    >
                      <Box data-oid="0wfpz2f">
                        <Text sx={{ fontWeight: "bold" }} data-oid="st47zgg">
                          Transaction Type
                        </Text>
                        <Text
                          sx={{ fontSize: 0, color: "fg.muted" }}
                          data-oid="wzn_p42"
                        >
                          Date
                        </Text>
                      </Box>
                      <Box sx={{ textAlign: "right" }} data-oid="lcxvgfe">
                        <Text
                          sx={{
                            fontWeight: "bold",
                            color: "success.fg", // or 'danger.fg' for negative amounts
                          }}
                          data-oid="lw89x5d"
                        >
                          Amount
                        </Text>
                        <Text
                          sx={{
                            fontSize: 0,
                            color: "fg.muted",
                            textTransform: "capitalize",
                          }}
                          data-oid="thmffxh"
                        >
                          Status
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          </Dialog>
        )}
      </Box>
    </DashboardLayout>
  );
}
