import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";

createRoot(document.getElementById("root")!).render(
  <StrictMode data-oid="zhf1zp5">
    <App data-oid=".6-nir2" />
  </StrictMode>,
);
