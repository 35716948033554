import { useState } from "react";
import { Box, Select, Textarea, Text, Button } from "@primer/react";
import { Dialog } from "@primer/react/experimental";
import { addDoc, collection, doc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useAuth } from "../contexts/AuthContext";
import { XIcon } from "@primer/octicons-react";

interface FeedbackModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  onSuccess?: () => void;
}

const FEEDBACK_TYPES = [
  { label: "Feedback", value: "Feedback" },
  { label: "Feature Request", value: "Feature Request" },
  { label: "Bug Report", value: "Bug Report" },
] as const;

export function FeedbackModal({
  isOpen,
  onDismiss,
  onSuccess,
}: FeedbackModalProps) {
  const [feedbackType, setFeedbackType] = useState<string>(
    FEEDBACK_TYPES[0].value,
  );
  const [feedbackText, setFeedbackText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useAuth();

  const handleSubmit = async () => {
    if (!feedbackText.trim() || !user) return;

    setIsSubmitting(true);
    try {
      await addDoc(collection(db, "feedback"), {
        feedback_type: feedbackType,
        feedback_text: feedbackText,
        user_ref: doc(db, "users", user.uid),
        user_email: user.email,
        created_at: new Date(),
      });

      onDismiss();
      onSuccess?.();
    } catch (error) {
      console.error("Error submitting feedback:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <Dialog
      title="Submit Feedback"
      onClose={onDismiss}
      width="xlarge"
      sx={{
        bg: "canvas.default",
        maxWidth: "50vw !important",
        margin: "0 auto",
      }}
      renderHeader={() => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            p: 3,
            borderBottom: "1px solid",
            borderColor: "border.default",
          }}
          data-oid="y2s56-c"
        >
          <Text sx={{ fontSize: 2, fontWeight: "bold" }} data-oid="_6qp:e0">
            Submit Feedback
          </Text>
          <Button
            variant="invisible"
            onClick={onDismiss}
            sx={{ color: "fg.muted", p: 1 }}
            data-oid="s:qvn9f"
          >
            <XIcon data-oid="q_4jflj" />
          </Button>
        </Box>
      )}
      footerButtons={[
        {
          content: "Cancel",
          onClick: onDismiss,
          variant: "default",
        },
        {
          content: "Submit Your Feedback",
          onClick: handleSubmit,
          variant: "primary",
          disabled: !feedbackText.trim() || isSubmitting,
          sx: {
            bg: "success.emphasis",
            color: "white",
            "&:hover:not([disabled])": {
              bg: "success.emphasis",
              color: "white",
            },
          },
        },
      ]}
      data-oid="uv5_9u6"
    >
      <Box sx={{ p: 3, bg: "canvas.default" }} data-oid="9c8p:vo">
        <Box sx={{ mb: 3 }} data-oid="yyc2ifc">
          <Text as="label" sx={{ display: "block", mb: 2 }} data-oid="_2g.zue">
            Feedback Type
          </Text>
          <Select
            value={feedbackType}
            onChange={(e) => setFeedbackType(e.target.value)}
            sx={{ width: "100%" }}
            data-oid="ozv:so4"
          >
            {FEEDBACK_TYPES.map((type) => (
              <Select.Option
                key={type.value}
                value={type.value}
                data-oid="tf5e.dp"
              >
                {type.label}
              </Select.Option>
            ))}
          </Select>
        </Box>

        <Box sx={{ mb: 3 }} data-oid="kj13d-t">
          <Text as="label" sx={{ display: "block", mb: 2 }} data-oid="-ey93f-">
            Your Feedback
          </Text>
          <Textarea
            value={feedbackText}
            onChange={(e) => setFeedbackText(e.target.value)}
            rows={7}
            placeholder="Please provide your feedback here..."
            sx={{ width: "100%" }}
            data-oid="3fv4m5i"
          />
        </Box>
      </Box>
    </Dialog>
  );
}
