import { Box, ActionList, Text } from "@primer/react";
import {
  HomeIcon,
  FileIcon,
  KeyIcon,
  PersonIcon,
  BookIcon,
  GraphIcon,
  CommentDiscussionIcon,
  PencilIcon,
} from "@primer/octicons-react";
import { useNavigate, useLocation } from "react-router-dom";
import { APP_VERSION, APP_NAME } from "../config/version";
import { useState } from "react";
import { FeedbackModal } from "./FeedbackModal";
import { Toast } from "./Toast";

export function MainNavigation() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] =
    useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);

  const handleFeedbackSuccess = () => {
    setShowToast(true);
    setTimeout(() => setShowToast(false), 3000);
  };

  return (
    <>
      <Box
        sx={{
          height: "100%",
          bg: "canvas.subtle",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        data-oid="-_ir.f:"
      >
        {/* Top Navigation Items */}
        <Box data-oid="d:.yp7i">
          <Box sx={{ px: 3, py: 3 }} data-oid="p15u6aw">
            <Text
              sx={{
                fontSize: 1,
                fontWeight: "bold",
                color: "fg.muted",
                textTransform: "uppercase",
                letterSpacing: "0.05em",
              }}
              data-oid="w:owzy6"
            >
              Navigation
            </Text>
          </Box>
          <ActionList data-oid="kkbntjb">
            <ActionList.Item
              onSelect={() => navigate("/dashboard")}
              active={location.pathname === "/dashboard"}
              data-oid="j2xff9g"
            >
              <ActionList.LeadingVisual data-oid="77x4jn7">
                <HomeIcon data-oid="eof67ik" />
              </ActionList.LeadingVisual>
              Home
            </ActionList.Item>
            <ActionList.Item
              onSelect={() => navigate("/dashboard/api-keys")}
              active={location.pathname === "/dashboard/api-keys"}
              data-oid="zyjom9k"
            >
              <ActionList.LeadingVisual data-oid=".2cq6ts">
                <KeyIcon data-oid="-g8n6uc" />
              </ActionList.LeadingVisual>
              API Keys
            </ActionList.Item>
            <ActionList.Item
              onSelect={() => navigate("/dashboard/templates")}
              active={
                location.pathname.startsWith("/dashboard/templates") &&
                !location.pathname.includes("/visual")
              }
              data-oid="sss981h"
            >
              <ActionList.LeadingVisual data-oid="y7o280:">
                <FileIcon data-oid="_05-fke" />
              </ActionList.LeadingVisual>
              Templates
            </ActionList.Item>
            <ActionList.Item
              onSelect={() => navigate("/dashboard/templates/visual")}
              active={location.pathname === "/dashboard/templates/visual"}
              data-oid="6g32hm4"
            >
              <ActionList.LeadingVisual data-oid="1.p8tyk">
                <PencilIcon data-oid="5bc5xjn" />
              </ActionList.LeadingVisual>
              Visual Editor
            </ActionList.Item>
            <ActionList.Item
              onSelect={() => navigate("/dashboard/usage")}
              active={location.pathname === "/dashboard/usage"}
              data-oid="-kg-ly3"
            >
              <ActionList.LeadingVisual data-oid="zp_o5bp">
                <GraphIcon data-oid="u-0p1rj" />
              </ActionList.LeadingVisual>
              Usage & Billing
            </ActionList.Item>
          </ActionList>
        </Box>

        {/* Bottom Section */}
        <Box data-oid="o:x8xmd">
          <ActionList data-oid="0q51ff3">
            <ActionList.Divider data-oid="nu4uadd" />
            <ActionList.Item
              onSelect={() => navigate("/dashboard/settings")}
              active={location.pathname === "/dashboard/settings"}
              data-oid="qap-7gw"
            >
              <ActionList.LeadingVisual data-oid="ddsgeeo">
                <PersonIcon data-oid="m32onvx" />
              </ActionList.LeadingVisual>
              Profile Settings
            </ActionList.Item>
            <ActionList.Item
              onSelect={() => setIsFeedbackModalOpen(true)}
              data-oid="y05l_sk"
            >
              <ActionList.LeadingVisual data-oid="u7:ll.:">
                <CommentDiscussionIcon data-oid="k:x6rvd" />
              </ActionList.LeadingVisual>
              Feedback
            </ActionList.Item>
            <ActionList.Item
              onSelect={() =>
                navigate("/api-documentation?section=generate-pdf")
              }
              active={location.pathname === "/api-documentation"}
              data-oid=".7eqz-s"
            >
              <ActionList.LeadingVisual data-oid="go0t455">
                <BookIcon data-oid="qww74zv" />
              </ActionList.LeadingVisual>
              API Documentation
            </ActionList.Item>
            {location.pathname === "/api-documentation" && (
              <ActionList.Group sx={{ pl: 4 }} data-oid="4b1cz_y">
                <ActionList.Item
                  onSelect={() =>
                    navigate("/api-documentation?section=generate-pdf")
                  }
                  active={location.search.includes("generate-pdf")}
                  data-oid="ls0i8i7"
                >
                  Generate PDF File
                </ActionList.Item>
                <ActionList.Item
                  onSelect={() =>
                    navigate("/api-documentation?section=generate-url")
                  }
                  active={location.search.includes("generate-url")}
                  data-oid="f4lvso2"
                >
                  Get Public PDF URL
                </ActionList.Item>
              </ActionList.Group>
            )}
          </ActionList>

          {/* Copyright and Version */}
          <Box
            sx={{
              px: 3,
              py: 3,
              borderTop: "1px solid",
              borderColor: "border.muted",
              mt: 2,
            }}
            data-oid="uwg9114"
          >
            <Text
              sx={{
                fontSize: 0,
                color: "fg.muted",
                display: "block",
                mb: 1,
              }}
              data-oid="2k8zmt3"
            >
              © 2024 {APP_NAME}
            </Text>
            <Text
              sx={{
                fontSize: 0,
                color: "fg.muted",
                display: "block",
              }}
              data-oid="xd:fu-g"
            >
              {APP_VERSION}
            </Text>
          </Box>
        </Box>
      </Box>

      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        onDismiss={() => setIsFeedbackModalOpen(false)}
        onSuccess={handleFeedbackSuccess}
        data-oid="yf-.nbu"
      />

      {showToast && (
        <Toast
          message="Thank you for your feedback! We appreciate your input and will review it carefully."
          data-oid="2e4htmg"
        />
      )}
    </>
  );
}
