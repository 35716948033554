import { Flash, Button } from "@primer/react";
import { XIcon } from "@primer/octicons-react";

interface ErrorAlertProps {
  message: string;
  onClose: () => void;
}

export function ErrorAlert({ message, onClose }: ErrorAlertProps) {
  return (
    <Flash
      variant="danger"
      sx={{
        mb: 3,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
      data-oid=".yyduxc"
    >
      {message}
      <Button
        onClick={onClose}
        variant="invisible"
        sx={{ p: 1, color: "fg.muted" }}
        aria-label="Close"
        data-oid="93nt1q1"
      >
        <XIcon data-oid="tf5xbds" />
      </Button>
    </Flash>
  );
}
