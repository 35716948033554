import { Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

export const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, loading } = useAuth();

  if (loading) {
    return (
      <div
        className="flex items-center justify-center min-h-screen"
        data-oid="0w.:nge"
      >
        Loading...
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" data-oid="-9wd5k0" />;
  }

  return <>{children}</>;
};
