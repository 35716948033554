import { Box } from "@primer/react";
import { useAuth } from "../contexts/AuthContext";
import { MainNavigation } from "./MainNavigation";
import { Header } from "./Header";

interface DashboardLayoutProps {
  children: React.ReactNode;
}

export function DashboardLayout({ children }: DashboardLayoutProps) {
  const { user } = useAuth();

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100vw",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
      data-oid="f_5l3ot"
    >
      {/* Header */}
      <Header data-oid="mh9m.bz" />

      {/* Main Content */}
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "calc(100vh - 64px)", // Subtract header height
          overflow: "hidden",
        }}
        data-oid="qz8rtgl"
      >
        {/* Navigation */}
        <Box
          sx={{
            width: 256,
            borderRight: "1px solid",
            borderColor: "border.default",
          }}
          data-oid="thub3ly"
        >
          <MainNavigation data-oid="0t4fhhb" />
        </Box>

        {/* Content */}
        <Box
          sx={{
            flex: 1,
            overflow: "hidden",
          }}
          data-oid="y6j8f14"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
}
