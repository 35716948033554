import { useState } from "react";
import {
  Header as PrimerHeader,
  Button,
  Text,
  Box,
  ActionMenu,
  ActionList,
  Avatar,
} from "@primer/react";
import {
  PlusIcon,
  KeyIcon,
  FileIcon,
  RocketIcon,
} from "@primer/octicons-react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { LogoTextShape, default_avatar } from "../assets";
import { collection, addDoc, doc, Timestamp } from "firebase/firestore";
import { db } from "../config/firebase";
import { sampleInvoice } from "../templates/sampleInvoice";
import { sampleTemplateData } from "../templates/sampleInvoice";
import { extractVariablesFromJson } from "../utils/jsonUtils";

export function Header() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/login");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };

  const handleNewApiKey = async () => {
    try {
      const userRef = doc(db, "users", user!.uid);
      const api_key = crypto.randomUUID().replace(/-/g, "");
      await addDoc(collection(db, "api_keys"), {
        api_key,
        user_ref: userRef,
        created_at: new Date(),
      });

      if (location.pathname === "/dashboard/api-keys") {
        navigate(0);
      } else {
        navigate("/dashboard/api-keys");
      }
    } catch (err) {
      console.error("Error generating API key:", err);
    }
  };

  const handleNewTemplate = async () => {
    try {
      const userRef = doc(db, "users", user!.uid);
      const now = Timestamp.now();

      // Extract variables from sample data
      const variables = extractVariablesFromJson(sampleTemplateData);

      // Create new template document
      const newTemplate = {
        name: "New Template",
        html: sampleInvoice,
        data_json: JSON.stringify(sampleTemplateData, null, 2),
        variables: variables,
        config: {
          margine_bottom: 4,
          margine_left: 4,
          margine_right: 4,
          margine_top: 4,
          orientation: "Landscape",
          paper_size: "Letter",
          print_background: true,
        },
        user_ref: userRef,
        created_at: now,
        updated_at: now,
      };

      const docRef = await addDoc(collection(db, "templates"), newTemplate);
      navigate(`/dashboard/templates/edit/${docRef.id}`);
    } catch (err) {
      console.error("Error creating new template:", err);
    }
  };

  return (
    <PrimerHeader
      sx={{
        width: "100%",
        px: 4,
        bg: "canvas.default",
        borderBottom: "1px solid",
        borderColor: "border.default",
        position: "relative",
        zIndex: 1,
      }}
      data-oid="ef3d.37"
    >
      {/* Logo */}
      <PrimerHeader.Item data-oid="dk29n6k">
        <img
          src={LogoTextShape}
          alt="Forge PDF"
          style={{ height: "32px" }}
          onClick={() => navigate("/dashboard")}
          role="button"
          className="cursor-pointer"
          data-oid="tlh:-68"
        />
      </PrimerHeader.Item>

      <PrimerHeader.Item full data-oid="yesfzjy" />

      {/* Create New Button */}
      <PrimerHeader.Item data-oid="f0zh0t4">
        <ActionMenu data-oid=":bbt9eh">
          <ActionMenu.Anchor data-oid="j5hpxfv">
            <Button leadingVisual={PlusIcon} sx={{ mr: 2 }} data-oid="jkqbqeh">
              New
            </Button>
          </ActionMenu.Anchor>
          <ActionMenu.Overlay
            sx={{
              position: "absolute",
              zIndex: 100,
              boxShadow: "shadow.large",
              border: "1px solid",
              borderColor: "border.default",
              borderRadius: 2,
              width: "240px",
            }}
            data-oid="hqmscan"
          >
            <ActionList data-oid="t-sjdzw">
              <ActionList.Item onSelect={handleNewApiKey} data-oid="jtu70ay">
                <ActionList.LeadingVisual data-oid="l9ikb06">
                  <KeyIcon data-oid="6qog.yv" />
                </ActionList.LeadingVisual>
                New API Key
              </ActionList.Item>
              <ActionList.Item onSelect={handleNewTemplate} data-oid="2nl6fe.">
                <ActionList.LeadingVisual data-oid="_1dhd_4">
                  <FileIcon data-oid="r9hpuy5" />
                </ActionList.LeadingVisual>
                New Template
              </ActionList.Item>
              <ActionList.Item
                onSelect={() => navigate("/dashboard/templates/prompt")}
                data-oid="1mj_n0u"
              >
                <ActionList.LeadingVisual data-oid="3kwhdgi">
                  <RocketIcon data-oid="s_tg3bd" />
                </ActionList.LeadingVisual>
                New Template with AI
              </ActionList.Item>
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </PrimerHeader.Item>

      {/* User Menu */}
      <PrimerHeader.Item data-oid="f7_bn.i">
        <ActionMenu data-oid="qrep..v">
          <ActionMenu.Anchor data-oid="e0h3wje">
            <Avatar
              src={user?.photoURL || default_avatar}
              alt={user?.email || "Anonymous User"}
              size={34}
              sx={{
                cursor: "pointer",
              }}
              data-oid="9_2gvvm"
            />
          </ActionMenu.Anchor>
          <ActionMenu.Overlay
            sx={{
              position: "absolute",
              zIndex: 100,
              boxShadow: "shadow.large",
              border: "1px solid",
              borderColor: "border.default",
              borderRadius: 2,
            }}
            data-oid="2r9ga9y"
          >
            <ActionList data-oid="4k_m3ed">
              <ActionList.Item data-oid="29ll7a_">
                Signed in as <br data-oid="m5ysxny" />
                <Text sx={{ fontWeight: "bold" }} data-oid="x3aia09">
                  {user?.email}
                </Text>
              </ActionList.Item>
              <ActionList.Divider data-oid="nknepmb" />
              <ActionList.Item
                onSelect={() => navigate("/dashboard/settings")}
                data-oid="s.-5fgh"
              >
                Profile Settings
              </ActionList.Item>
              <ActionList.Divider data-oid="h66nwho" />
              <ActionList.Item
                variant="danger"
                onClick={handleLogout}
                data-oid="joj.do3"
              >
                Sign out
              </ActionList.Item>
            </ActionList>
          </ActionMenu.Overlay>
        </ActionMenu>
      </PrimerHeader.Item>
    </PrimerHeader>
  );
}
