import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import { Box, Button, Text, TextInput, FormControl } from "@primer/react";
import { LogoTextShape, login_image } from "../assets";
import { getAuthErrorMessage } from "../utils/errorMessages";
import { ErrorAlert } from "../components/ErrorAlert";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "../config/firebase";

export default function Login() {
  const { signInWithGoogle, signInWithEmail, signUpWithEmail } = useAuth();
  const navigate = useNavigate();
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSignUp, setIsSignUp] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const initializeWallet = async (userId: string) => {
    try {
      const walletRef = doc(db, "wallet", userId);
      const walletDoc = await getDoc(walletRef);

      if (!walletDoc.exists()) {
        const userRef = doc(db, "users", userId);
        await setDoc(walletRef, {
          user_ref: userRef,
          free_usage: 0,
          free_usage_limit: 1000,
          token_usage: 0,
          amount: 0,
          available_tokens: 0,
          created_at: new Date(),
          updated_at: new Date(),
        });
        console.log("Wallet created successfully for user:", userId);
      }
    } catch (error) {
      console.error("Error initializing wallet:", error);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const userCredential = await signInWithGoogle();
      if (userCredential.user) {
        await initializeWallet(userCredential.user.uid);
      }
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      setError(getAuthErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailAuth = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      setError(null);
      const userCredential = isSignUp
        ? await signUpWithEmail(email, password)
        : await signInWithEmail(email, password);
      if (userCredential.user) {
        await initializeWallet(userCredential.user.uid);
      }
      navigate("/dashboard");
    } catch (error) {
      console.error("Authentication error:", error);
      setError(getAuthErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        bg: "canvas.default",
      }}
      data-oid="1jhkrlb"
    >
      {/* Left Panel */}
      <Box
        sx={{
          flex: "0 0 50%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          p: 6,
          gap: 5,
        }}
        data-oid="8fjga9q"
      >
        <Box sx={{ mb: 4 }} data-oid="5lu.v9_">
          <img
            src={LogoTextShape}
            alt="Forge PDF"
            style={{
              height: "40px",
            }}
            className="w-[160px] h-[16px]"
            data-oid="6n:u.7c"
          />
        </Box>

        <Box
          sx={{
            maxWidth: "360px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
          data-oid="p._5jcr"
        >
          <Box data-oid="2ehg8xk">
            <Text
              as="h1"
              sx={{ fontSize: 4, fontWeight: "bold", mb: 2 }}
              data-oid="v5gznph"
            >
              {isSignUp ? "Create your account" : "Welcome back"}
            </Text>
            <Text sx={{ color: "fg.muted" }} data-oid="1dz:pks">
              {isSignUp
                ? "Get started with Forge PDF"
                : "Sign in to continue to Forge PDF"}
            </Text>
          </Box>

          {error && (
            <ErrorAlert
              message={error}
              onClose={() => setError(null)}
              data-oid="3je5lxv"
            />
          )}

          <form onSubmit={handleEmailAuth} data-oid="ciygd35">
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 3 }}
              data-oid="kmhe73k"
            >
              <FormControl data-oid=":lolpvs">
                <FormControl.Label data-oid="hs:_gp1">
                  Email address
                </FormControl.Label>
                <TextInput
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter your email"
                  required
                  block
                  data-oid="f9s_wxr"
                />
              </FormControl>

              <FormControl data-oid="1258s:3">
                <FormControl.Label data-oid="py9klei">
                  Password
                </FormControl.Label>
                <TextInput
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                  required
                  block
                  data-oid="kvxn:2i"
                />
              </FormControl>

              <Button
                variant="primary"
                type="submit"
                disabled={isLoading}
                sx={{ width: "100%", height: "44px" }}
                data-oid="q59ze_f"
              >
                {isLoading
                  ? "Please wait..."
                  : isSignUp
                    ? "Create account"
                    : "Sign in"}
              </Button>
            </Box>
          </form>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 2 }}
            data-oid="0yn3hzf"
          >
            <Box
              sx={{ flex: 1, height: "1px", bg: "border.default" }}
              data-oid="4xs_8v:"
            />

            <Text sx={{ color: "fg.muted" }} data-oid="8g2073p">
              or
            </Text>
            <Box
              sx={{ flex: 1, height: "1px", bg: "border.default" }}
              data-oid="sdhny9y"
            />
          </Box>

          <Button
            variant="default"
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            sx={{
              width: "100%",
              height: "44px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              border: "1px solid",
              borderColor: "border.default",
              position: "relative",
              paddingLeft: "48px",
            }}
            data-oid="1zei7_k"
          >
            <Box
              sx={{
                position: "absolute",
                left: "16px",
                display: "flex",
                alignItems: "center",
              }}
              data-oid="jtm3cpf"
            >
              <img
                src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                alt="Google"
                style={{ width: "18px", height: "18px" }}
                data-oid=":ym0b2p"
              />
            </Box>
            Continue with Google
          </Button>

          <Box sx={{ textAlign: "center" }} data-oid="04p8f2h">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => setIsSignUp(!isSignUp)}
              data-oid="z96gy9p"
            >
              <Text sx={{ color: "fg.muted" }} data-oid="ahi2b6a">
                {isSignUp
                  ? "Already have an account?"
                  : "Don't have an account?"}
              </Text>
              <Text
                sx={{ color: "accent.fg", fontWeight: "bold" }}
                data-oid="4afzs0m"
              >
                {isSignUp ? "Sign in" : "Sign up"}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Right Panel */}
      <Box
        sx={{
          flex: "0 0 50%",
          bg: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          p: 6,
          borderLeft: "1px solid",
          borderColor: "border.default",
        }}
        data-oid="4h8vo65"
      >
        <img
          src={login_image}
          alt="Login illustration"
          style={{
            maxWidth: "50%",
            height: "auto",
            objectFit: "contain",
          }}
          data-oid="dtetk0v"
        />
      </Box>
    </Box>
  );
}
