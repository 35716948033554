import { useState, useRef, createRef, useEffect } from "react";
import {
  Box,
  Button,
  Text,
  ActionMenu,
  TextInput,
  ActionList,
  UnderlineNav,
  FormControl,
  Tooltip,
  ButtonGroup,
} from "@primer/react";
import { Dialog } from "@primer/react/experimental";
import { DashboardLayout } from "../components/DashboardLayout";
import {
  PlusIcon,
  CalendarIcon,
  TagIcon,
  HashIcon,
  DeviceCameraIcon,
  ArrowLeftIcon,
  EyeIcon,
  XIcon,
  TypographyIcon,
  ImageIcon,
  CopyIcon,
} from "@primer/octicons-react";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { vscodeLight } from "@uiw/codemirror-theme-vscode";
import { useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { Toast } from "../components/Toast";
import { useNavigate } from "react-router-dom";
import { JSONPath } from "jsonpath-plus";
import { sampleInvoice } from "../templates/sampleInvoice";
import { Timestamp } from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { collection, query, where, getDocs } from "firebase/firestore";
import { storage } from "../config/firebase";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { EditorView, keymap, ViewPlugin, ViewUpdate } from "@codemirror/view";
import { StateField, StateEffect, Extension } from "@codemirror/state";
import { Decoration, DecorationSet } from "@codemirror/view";
import { autocompletion } from "@codemirror/autocomplete";
import { bracketMatching } from "@codemirror/language";
import { defaultKeymap } from "@codemirror/commands";
import { history } from "@codemirror/commands";
import { indentOnInput } from "@codemirror/language";
import { lineNumbers } from "@codemirror/view";
import { highlightSelectionMatches } from "@codemirror/search";

// Add these constants at the top of the file, outside the component
const PAPER_SIZES = {
  A1: {
    width: "594mm",
    height: "841mm",
  },
  A2: {
    width: "420mm",
    height: "594mm",
  },
  A3: {
    width: "297mm",
    height: "420mm",
  },
  A4: {
    width: "210mm",
    height: "297mm",
  },
  A5: {
    width: "148mm",
    height: "210mm",
  },
  A6: {
    width: "105mm",
    height: "148mm",
  },
  Letter: {
    width: "215.9mm",
    height: "279.4mm",
  },
  Legal: {
    width: "215.9mm",
    height: "355.6mm",
  },
  Tabloid: {
    width: "279.4mm",
    height: "431.8mm",
  },
  Ledger: {
    width: "431.8mm",
    height: "279.4mm",
  },
};

// Add this helper function to replace variables with values
const replaceVariablesWithValues = (
  content: string,
  jsonData: string,
): string => {
  try {
    const data = JSON.parse(jsonData);

    // Function to get nested object value using dot notation
    const getNestedValue = (obj: any, path: string) => {
      return path.split(".").reduce((prev, curr) => {
        return prev ? prev[curr] : undefined;
      }, obj);
    };

    // Replace all {{variable}} occurrences with their values
    return content.replace(/\{\{([^}]+)\}\}/g, (match, variable) => {
      // Remove any whitespace
      const cleanVariable = variable.trim();

      // Check if it's a formatted variable (date, currency, etc.)
      const formatMatch = cleanVariable.match(
        /^(date|currency|barcode|qrcode)\s+(.+)$/,
      );

      if (formatMatch) {
        const [, format, varName] = formatMatch;
        const value = getNestedValue(data, varName);

        if (value !== undefined) {
          switch (format) {
            case "date":
              return new Date(value).toLocaleDateString();
            case "currency":
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(value);
            case "barcode":
            case "qrcode":
              return value; // You might want to add actual barcode/QR code generation here
            default:
              return value;
          }
        }
      } else {
        const value = getNestedValue(data, cleanVariable);
        return value !== undefined ? value : match;
      }

      return match; // Keep original if no value found
    });
  } catch (error) {
    console.error("Error replacing variables:", error);
    return content;
  }
};

// Add this type definition
interface ApiKey {
  id: string;
  api_key: string;
}

// Add these helper functions after the existing interfaces
const formatCSS = (css: string): string => {
  // Remove all newlines and extra spaces
  const minified = css.replace(/\s+/g, " ").trim();

  // Split by closing brace and format each rule
  return minified
    .split("}")
    .map((rule) => {
      if (!rule.trim()) return "";

      // Split selector and properties
      const [selector, properties] = rule.split("{");
      if (!properties) return "";

      // Format properties to be on one line
      const formattedProperties = properties
        .split(";")
        .map((prop) => prop.trim())
        .filter((prop) => prop)
        .join("; ");

      return `${selector.trim()} { ${formattedProperties} }`;
    })
    .filter((rule) => rule)
    .join("\n");
};

const formatHTMLContent = (html: string): string => {
  // First, handle the style tag content
  html = html.replace(/<style>([\s\S]*?)<\/style>/g, (match, styleContent) => {
    const formattedCSS = formatCSS(styleContent);
    return `<style>\n${formattedCSS}\n</style>`;
  });

  // Then format the HTML structure
  let formatted = "";
  let indent = 0;
  let lastTag = "";

  // Split by tags but preserve whitespace within tags
  const parts = html.split(/(<[^>]+>)/);

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i];
    if (!part.trim()) continue;

    if (part.startsWith("</")) {
      // Closing tag
      indent--;
      const content = parts[i - 1]?.trim();
      if (content && !content.startsWith("<") && content.length < 50) {
        // Short content - keep it on one line with its tags
        formatted = formatted.slice(0, -1); // Remove the last newline
        formatted += part + "\n";
      } else {
        formatted += "  ".repeat(Math.max(0, indent)) + part + "\n";
      }
    } else if (part.startsWith("<")) {
      // Opening tag
      formatted += "  ".repeat(indent) + part + "\n";
      if (
        !part.startsWith("<br") &&
        !part.startsWith("<img") &&
        !part.startsWith("<input") &&
        !part.endsWith("/>")
      ) {
        indent++;
      }
      lastTag = part;
    } else {
      // Text content
      const nextTag = parts[i + 1];
      if (nextTag && part.length < 50 && !part.includes("\n")) {
        // Short content - keep it on the same line
        formatted = formatted.slice(0, -1); // Remove the last newline
        formatted += part;
      } else {
        formatted += "  ".repeat(indent) + part + "\n";
      }
    }
  }

  return formatted;
};

// Add this function to handle image upload
const uploadImage = async (file: File): Promise<string> => {
  const storageRef = ref(storage, `template-images/${Date.now()}_${file.name}`);
  await uploadBytes(storageRef, file);
  return await getDownloadURL(storageRef);
};

// Add this new type for our markers
interface VariableMarker {
  from: number;
  to: number;
  variable: string;
}

// Add variable highlighting styles
const variableTheme = EditorView.baseTheme({
  ".cm-variable": {
    backgroundColor: "#fff8c5",
    borderRadius: "2px",
    cursor: "pointer",
    padding: "0 2px",
  },
});

// Create a custom highlighter for variables
const variableHighlighter = ViewPlugin.fromClass(
  class {
    decorations: DecorationSet;

    constructor(view: EditorView) {
      this.decorations = this.getDecorations(view);
    }

    update(update: ViewUpdate) {
      if (update.docChanged || update.viewportChanged) {
        this.decorations = this.getDecorations(update.view);
      }
    }

    getDecorations(view: EditorView) {
      let decorations: Array<{ from: number; to: number; variable: string }> =
        [];

      for (let { from, to } of view.visibleRanges) {
        const text = view.state.doc.sliceString(from, to);
        const variableRegex = /\{\{([^}]+)\}\}/g;
        let match;

        while ((match = variableRegex.exec(text)) !== null) {
          const start = from + match.index;
          const end = start + match[0].length;
          const variable = match[1].trim();

          decorations.push({
            from: start,
            to: end,
            variable,
          });
        }
      }

      return Decoration.set(
        decorations.map(({ from, to, variable }) =>
          Decoration.mark({
            class: "cm-variable",
            attributes: { "data-variable": variable },
          }).range(from, to),
        ),
      );
    }
  },
  {
    decorations: (v) => v.decorations,

    eventHandlers: {
      click: (e: MouseEvent, view: EditorView) => {
        const target = e.target as HTMLElement;
        if (target.classList.contains("cm-variable")) {
          const variable = target.getAttribute("data-variable");
          if (variable) {
            // Get the coordinates for the menu
            const rect = target.getBoundingClientRect();

            // We'll need to access these in the component
            if (typeof window.handleVariableClick === "function") {
              window.handleVariableClick(variable, rect);
            }
          }
        }
      },
    },
  },
);

// Declare the global function type
declare global {
  interface Window {
    handleVariableClick?: (variable: string, rect: DOMRect) => void;
  }
}

// Add this interface for the editor ref
interface CodeMirrorRef {
  view: EditorView;
}

export default function TemplateEditor() {
  const { id } = useParams();
  const [htmlContent, setHtmlContent] = useState("");
  const [isVariableModalOpen, setIsVariableModalOpen] = useState(false);
  const [newVariable, setNewVariable] = useState("");
  const editorRef = useRef<CodeMirrorRef | null>(null);
  const [selectedVariable, setSelectedVariable] = useState<string | null>(null);
  const [menuPosition, setMenuPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showToast, setShowToast] = useState(false);
  const [templateName, setTemplateName] = useState("New Template");
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("editor");
  const [config, setConfig] = useState({
    margine_bottom: 4,
    margine_left: 4,
    margine_right: 4,
    margine_top: 4,
    orientation: "Portrait",
    paper_size: "A4",
    print_background: true,
  });
  const [jsonData, setJsonData] = useState<string>("");
  const [variables, setVariables] = useState<string[]>([]);
  const [showPreview, setShowPreview] = useState(true);
  const [previewHTML, setPreviewHTML] = useState("");
  const { user } = useAuth();
  const [isTestingTemplate, setIsTestingTemplate] = useState(false);
  const [testResult, setTestResult] = useState<{
    success: boolean;
    message: string;
    pdfUrl?: string;
  } | null>(null);
  const [markers, setMarkers] = useState<VariableMarker[]>([]);

  useEffect(() => {
    const loadTemplate = async () => {
      if (!id) {
        setError("No template ID provided");
        setLoading(false);
        return;
      }

      try {
        const templateDoc = await getDoc(doc(db, "templates", id));
        if (templateDoc.exists()) {
          const templateData = templateDoc.data();
          // Load the raw HTML without formatting
          setHtmlContent(templateData.html || sampleInvoice);
          setTemplateName(templateData.name || "New Template");

          // Ensure config has all required fields
          const defaultConfig = {
            margine_bottom: 4,
            margine_left: 4,
            margine_right: 4,
            margine_top: 4,
            orientation: "Portrait",
            paper_size: "A4",
            print_background: true,
          };

          setConfig({
            ...defaultConfig,
            ...(templateData.config || {}),
          });

          if (templateData.data_json) {
            setJsonData(templateData.data_json);
          }
          if (templateData.variables) {
            setVariables(
              Array.isArray(templateData.variables)
                ? templateData.variables
                : [],
            );
          }
        } else {
          setHtmlContent(sampleInvoice);
          setConfig({
            margine_bottom: 4,
            margine_left: 4,
            margine_right: 4,
            margine_top: 4,
            orientation: "Portrait",
            paper_size: "A4",
            print_background: true,
          });
        }
      } catch (err) {
        console.error("Error loading template:", err);
        setError("Failed to load template");
      } finally {
        setLoading(false);
      }
    };

    loadTemplate();
  }, [id]);

  useEffect(() => {
    // Replace variables in the HTML content with actual values
    const contentWithValues = jsonData
      ? replaceVariablesWithValues(htmlContent, jsonData)
      : htmlContent;

    // Set the preview HTML directly without the extra wrapper
    setPreviewHTML(contentWithValues);
  }, [config, htmlContent, jsonData]);

  // Set up the click handler
  useEffect(() => {
    window.handleVariableClick = (variable: string, rect: DOMRect) => {
      setSelectedVariable(variable);
      setMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
      });
    };

    return () => {
      window.handleVariableClick = undefined;
    };
  }, []);

  // Restore applyVariableFormat function
  const applyVariableFormat = (format: string) => {
    if (!selectedVariable || !editorRef.current?.view) return;

    const view = editorRef.current.view;
    const content = view.state.doc.toString();

    // Find the current variable in the content
    const regex = new RegExp(`\\{\\{${selectedVariable}\\}\\}`);
    const match = regex.exec(content);

    if (match) {
      const from = match.index;
      const to = from + match[0].length;

      let newValue = "";
      switch (format) {
        case "date":
          newValue = `{{date ${selectedVariable}}}`;
          break;
        case "currency":
          newValue = `{{currency ${selectedVariable}}}`;
          break;
        case "barcode":
          newValue = `{{barcode ${selectedVariable}}}`;
          break;
        case "qrcode":
          newValue = `{{qrcode ${selectedVariable}}}`;
          break;
      }

      view.dispatch({
        changes: {
          from,
          to,
          insert: newValue,
        },
      });
    }

    setSelectedVariable(null);
    setMenuPosition(null);
  };

  // Update handleEditorChange to just update content
  const handleEditorChange = (value: string) => {
    setHtmlContent(value);
  };

  const handleSave = async () => {
    if (!id) return;

    try {
      // Get the raw HTML content from the editor
      const rawHtmlContent = htmlContent;

      await updateDoc(doc(db, "templates", id), {
        html: rawHtmlContent,
        name: templateName,
        config: config,
        data_json: jsonData,
        variables: variables,
        updated_at: Timestamp.now(),
      });
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    } catch (err) {
      console.error("Error saving template:", err);
      setError("Failed to save template");
    }
  };

  const extractJsonPaths = (jsonString: string) => {
    try {
      const jsonObj = JSON.parse(jsonString);
      console.log("Parsed JSON:", jsonObj);

      // Function to recursively get all paths
      const getPaths = (obj: any, prefix: string = ""): string[] => {
        let paths: string[] = [];

        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            const newPath = prefix ? `${prefix}.${key}` : key;

            // Add the current path
            paths.push(newPath);

            // If value is an object and not null, recurse
            if (value && typeof value === "object" && !Array.isArray(value)) {
              paths = paths.concat(getPaths(value, newPath));
            }
          }
        }

        return paths;
      };

      // Get all paths
      const allPaths = getPaths(jsonObj);
      console.log("All paths:", allPaths);

      setVariables(allPaths);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      setError("Invalid JSON format");
    }
  };

  const handleTestTemplate = async () => {
    setIsTestingTemplate(true);
    setTestResult(null);

    try {
      // Check if JSON data exists
      if (!jsonData.trim()) {
        setTestResult({
          success: false,
          message:
            "Please provide sample data in the Data Mapping tab before testing",
        });
        return;
      }

      // Validate JSON data
      try {
        JSON.parse(jsonData);
      } catch (e) {
        setTestResult({
          success: false,
          message: "Invalid JSON data format",
        });
        return;
      }

      // Check for API key
      const apiKeysRef = collection(db, "api_keys");
      const apiKeysQuery = query(
        apiKeysRef,
        where("user_ref", "==", doc(db, "users", user!.uid)),
      );

      const apiKeysSnapshot = await getDocs(apiKeysQuery);

      if (apiKeysSnapshot.empty) {
        setTestResult({
          success: false,
          message: "You need to create an API key first",
        });
        return;
      }

      // Save the template first
      if (!id) return;

      try {
        await updateDoc(doc(db, "templates", id), {
          html: htmlContent,
          name: templateName,
          config: config,
          data_json: jsonData,
          variables: variables,
          updated_at: Timestamp.now(),
        });
      } catch (err) {
        setTestResult({
          success: false,
          message: "Failed to save template before testing",
        });
        return;
      }

      // Get the first API key and proceed with API call
      const apiKey = apiKeysSnapshot.docs[0].data().api_key;

      // Make the API call
      const response = await fetch("https://v1-stocfzcgjq-uc.a.run.app/pdf", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${apiKey}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          template_id: id,
          data: JSON.parse(jsonData),
        }),
      });

      // Handle the response
      if (response.ok) {
        // Convert the PDF blob to a URL
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);

        setTestResult({
          success: true,
          message: "PDF generated successfully!",
          pdfUrl,
        });
      } else {
        const errorText = await response.text();
        setTestResult({
          success: false,
          message: `Error: ${errorText}`,
        });
      }
    } catch (error) {
      setTestResult({
        success: false,
        message: `Error: ${error instanceof Error ? error.message : "Unknown error occurred"}`,
      });
    } finally {
      setIsTestingTemplate(false);
    }
  };

  useEffect(() => {
    // Initialize config with default values if it's undefined
    if (!config) {
      setConfig({
        margine_bottom: 4,
        margine_left: 4,
        margine_right: 4,
        margine_top: 4,
        orientation: "Portrait",
        paper_size: "A4",
        print_background: true,
      });
    }
  }, [config]);

  const renderTabContent = () => {
    switch (activeTab) {
      case "editor":
        return (
          <Box
            display="flex"
            flexDirection="row"
            height="100%"
            data-oid="9fhxf_h"
          >
            {/* Editor */}
            <Box
              flex={1}
              p={3}
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
              }}
              data-oid="c:p9ze2"
            >
              <Box
                sx={{
                  mb: 3,
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  flexShrink: 0,
                }}
                data-oid="rzdtolo"
              >
                <Text as="h2" sx={{ mb: 2 }} data-oid="ckr8ti-">
                  Template Editor
                </Text>
                <Box sx={{ flex: 1 }} data-oid="39x.kxl" />

                <ButtonGroup data-oid="g6lmu45">
                  {/* Upload Image Action */}
                  <Button
                    variant="invisible"
                    onClick={() => {
                      document.getElementById("image-upload")?.click();
                    }}
                    data-oid="rn7kz0f"
                  >
                    Upload Image
                  </Button>
                  <input
                    type="file"
                    id="image-upload"
                    accept="image/*"
                    style={{ display: "none" }}
                    onChange={async (e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        try {
                          const imageUrl = await uploadImage(file);
                          const imageTag = `<img src="${imageUrl}" alt="${file.name}" style="max-width: 100%;" />`;
                          if (editorRef.current?.view) {
                            const view = editorRef.current.view;
                            const pos = view.state.selection.main.head;
                            view.dispatch({
                              changes: {
                                from: pos,
                                insert: imageTag,
                              },
                            });
                          }
                        } catch (error) {
                          console.error("Error uploading image:", error);
                          setError("Failed to upload image");
                        }
                      }
                    }}
                    data-oid="d6pyahm"
                  />

                  {/* Add Variable Action */}
                  <Button
                    variant="invisible"
                    onClick={() => setIsVariableModalOpen(true)}
                    data-oid="xhobbdd"
                  >
                    Add Variable
                  </Button>
                </ButtonGroup>
              </Box>

              <Box
                sx={{
                  flex: 1,
                  minHeight: 0,
                  position: "relative",
                }}
                data-oid="-xi382f"
              >
                <CodeMirror
                  value={htmlContent}
                  height="100%"
                  theme={vscodeLight}
                  style={{
                    height: "100%",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                  basicSetup={{
                    lineNumbers: true,
                    highlightActiveLine: true,
                    highlightSelectionMatches: true,
                    autocompletion: true,
                    bracketMatching: true,
                    indentOnInput: true,
                    foldGutter: true,
                    history: true,
                  }}
                  extensions={[
                    html({ selfClosingTags: true }),
                    EditorView.lineWrapping,
                    variableHighlighter,
                    variableTheme,
                  ]}
                  onChange={(value, viewUpdate) => {
                    setHtmlContent(value);
                  }}
                  ref={editorRef}
                  data-oid="s518dwx"
                />
              </Box>
            </Box>
          </Box>
        );

      case "mapping":
        return (
          <Box
            sx={{
              p: 4,
              height: "100%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            data-oid="gj6lzgb"
          >
            <Box
              sx={{ display: "flex", justifyContent: "flex-end" }}
              data-oid="emv2sjd"
            >
              <Button
                variant="primary"
                size="medium"
                disabled={!jsonData.trim()}
                onClick={() => extractJsonPaths(jsonData)}
                data-oid="mevqwft"
              >
                Map JSON Data To Variables
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 3,
                height: "calc(100% - 60px)",
              }}
              data-oid="-jdx3yb"
            >
              {/* Left side - JSON input */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
                data-oid="f8d0x0e"
              >
                <Text as="h3" sx={{ mb: 2 }} data-oid="rtt_i90">
                  JSON Data
                </Text>
                <Box
                  as="textarea"
                  value={jsonData}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setJsonData(e.target.value)
                  }
                  sx={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                    fontFamily: "monospace",
                    resize: "none",
                    p: 2,
                    border: "1px solid",
                    borderColor: "border.default",
                    borderRadius: 2,
                    fontSize: 1,
                    lineHeight: 1.4,
                    outline: "none",
                    "&:focus": {
                      borderColor: "accent.emphasis",
                      boxShadow: "inset 0 0 0 1px accent.emphasis",
                    },
                  }}
                  placeholder="Paste your JSON data here..."
                  data-oid="or43fo3"
                />
              </Box>

              {/* Right side - Variables list */}
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                }}
                data-oid="xo0pe:4"
              >
                <Text as="h3" sx={{ mb: 2 }} data-oid="8u3bb-j">
                  Mapped Variables
                </Text>
                <Box
                  sx={{
                    flex: 1,
                    border: "1px solid",
                    borderColor: "border.default",
                    borderRadius: 2,
                    p: 3,
                    overflow: "auto",
                  }}
                  data-oid="ywazs9b"
                >
                  {variables.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                      data-oid="o.epj58"
                    >
                      {variables.map((variable, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            px: 2,
                            py: 1,
                            borderRadius: "2em",
                            bg: "accent.subtle",
                            color: "accent.fg",
                            fontSize: 1,
                            fontWeight: "bold",
                            cursor: "pointer",
                            "&:hover": {
                              bg: "accent.muted",
                            },
                          }}
                          data-oid="p6-k6lq"
                        >
                          {variable}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "fg.muted",
                      }}
                      data-oid="fnlhxux"
                    >
                      Start map your data to variables
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        );

      case "settings":
        return (
          <Box
            sx={{ p: 4, height: "100%", overflow: "auto" }}
            data-oid="9qfltzi"
          >
            <Text as="h2" sx={{ mb: 3 }} data-oid="6ji:bi5">
              Document Settings
            </Text>

            {/* Margins Section */}
            <Box sx={{ mb: 4 }} data-oid="rwo6d6l">
              <Text as="h3" sx={{ mb: 2, fontSize: 2 }} data-oid="erbffqo">
                Margins
              </Text>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gap: 3,
                }}
                data-oid="yh.ia:7"
              >
                <FormControl data-oid="8nrp9dr">
                  <FormControl.Label data-oid="mb-muig">
                    Top (mm)
                  </FormControl.Label>
                  <TextInput
                    type="number"
                    value={config?.margine_top ?? 4}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        margine_top: parseInt(e.target.value),
                      }))
                    }
                    step={1}
                    min={0}
                    data-oid="sp-9uhj"
                  />
                </FormControl>
                <FormControl data-oid="c-9cza6">
                  <FormControl.Label data-oid="6:kzu0o">
                    Bottom (mm)
                  </FormControl.Label>
                  <TextInput
                    type="number"
                    value={config?.margine_bottom ?? 4}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        margine_bottom: parseInt(e.target.value),
                      }))
                    }
                    step={1}
                    min={0}
                    data-oid="65e4ktb"
                  />
                </FormControl>
                <FormControl data-oid="fuc6djm">
                  <FormControl.Label data-oid="2j56:hn">
                    Left (mm)
                  </FormControl.Label>
                  <TextInput
                    type="number"
                    value={config?.margine_left ?? 4}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        margine_left: parseInt(e.target.value),
                      }))
                    }
                    step={1}
                    min={0}
                    data-oid="3zurxxk"
                  />
                </FormControl>
                <FormControl data-oid="ya7kdu9">
                  <FormControl.Label data-oid="dt3:vvj">
                    Right (mm)
                  </FormControl.Label>
                  <TextInput
                    type="number"
                    value={config?.margine_right ?? 4}
                    onChange={(e) =>
                      setConfig((prev) => ({
                        ...prev,
                        margine_right: parseInt(e.target.value),
                      }))
                    }
                    step={1}
                    min={0}
                    data-oid="_-s4v6k"
                  />
                </FormControl>
              </Box>
            </Box>

            {/* Paper Settings Section */}
            <Box data-oid="j7fzy8j">
              <Text as="h3" sx={{ mb: 2, fontSize: 2 }} data-oid="7f2bqye">
                Paper Settings
              </Text>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 3,
                  maxWidth: "100%",
                }}
                data-oid="-svwghl"
              >
                <FormControl data-oid="2ysnxbd">
                  <FormControl.Label data-oid="6pt8ffx">Size</FormControl.Label>
                  <ActionMenu data-oid="at:jgwf">
                    <ActionMenu.Button
                      sx={{ width: "100%" }}
                      data-oid="_e5zl6x"
                    >
                      {config?.paper_size ?? "A4"}
                    </ActionMenu.Button>
                    <ActionMenu.Overlay data-oid="85qe0uu">
                      <ActionList data-oid="_dnh:nv">
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({ ...prev, paper_size: "A1" }))
                          }
                          data-oid="94zt.xt"
                        >
                          A1
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({ ...prev, paper_size: "A2" }))
                          }
                          data-oid="s7y603q"
                        >
                          A2
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({ ...prev, paper_size: "A3" }))
                          }
                          data-oid="rlwh91m"
                        >
                          A3
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({ ...prev, paper_size: "A4" }))
                          }
                          data-oid="z81hma4"
                        >
                          A4
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({ ...prev, paper_size: "A5" }))
                          }
                          data-oid="wjyo_aq"
                        >
                          A5
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({ ...prev, paper_size: "A6" }))
                          }
                          data-oid="900ugk:"
                        >
                          A6
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({
                              ...prev,
                              paper_size: "Letter",
                            }))
                          }
                          data-oid="oapp5_9"
                        >
                          Letter
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({
                              ...prev,
                              paper_size: "Legal",
                            }))
                          }
                          data-oid="4go.6df"
                        >
                          Legal
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({
                              ...prev,
                              paper_size: "Tabloid",
                            }))
                          }
                          data-oid="4bwqchp"
                        >
                          Tabloid
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({
                              ...prev,
                              paper_size: "Ledger",
                            }))
                          }
                          data-oid="lnfwc0u"
                        >
                          Ledger
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </FormControl>

                <FormControl data-oid="u:vwu-d">
                  <FormControl.Label data-oid="8qu5g.7">
                    Orientation
                  </FormControl.Label>
                  <ActionMenu data-oid="kait:5l">
                    <ActionMenu.Button
                      sx={{ width: "100%" }}
                      data-oid="xy1.:b8"
                    >
                      {config?.orientation ?? "Portrait"}
                    </ActionMenu.Button>
                    <ActionMenu.Overlay data-oid="0isscf6">
                      <ActionList data-oid="e8tb75e">
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({
                              ...prev,
                              orientation: "Portrait",
                            }))
                          }
                          data-oid="lh6kxcq"
                        >
                          Portrait
                        </ActionList.Item>
                        <ActionList.Item
                          onSelect={() =>
                            setConfig((prev) => ({
                              ...prev,
                              orientation: "Landscape",
                            }))
                          }
                          data-oid="tjgtjm:"
                        >
                          Landscape
                        </ActionList.Item>
                      </ActionList>
                    </ActionMenu.Overlay>
                  </ActionMenu>
                </FormControl>
              </Box>
            </Box>
          </Box>
        );

      default:
        return null;
    }
  };

  return (
    <DashboardLayout data-oid="4lwcbe2">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          overflow: "hidden",
        }}
        data-oid="4fifj8n"
      >
        {/* Navigation and Controls */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid",
            borderColor: "border.default",
            px: 3,
            gap: 3,
            height: "48px",
          }}
          data-oid="5ucyc7q"
        >
          {/* Back Button */}
          <Button
            onClick={() => navigate("/dashboard/templates")}
            variant="invisible"
            leadingVisual={ArrowLeftIcon}
            sx={{ color: "fg.muted" }}
            data-oid="u_pa5:v"
          >
            Back
          </Button>

          <UnderlineNav aria-label="Template Editor" data-oid="q0-z1l3">
            <UnderlineNav.Link
              selected={activeTab === "editor"}
              onClick={() => setActiveTab("editor")}
              data-oid="cirq9ky"
            >
              Editor
            </UnderlineNav.Link>
            <UnderlineNav.Link
              selected={activeTab === "mapping"}
              onClick={() => setActiveTab("mapping")}
              data-oid="pddsrg_"
            >
              Data Mapping
            </UnderlineNav.Link>
            <UnderlineNav.Link
              selected={activeTab === "settings"}
              onClick={() => setActiveTab("settings")}
              data-oid="y2j:4l0"
            >
              Settings
            </UnderlineNav.Link>
          </UnderlineNav>

          {/* Template Name and Save Button */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
            }}
            data-oid="5alu-t2"
          >
            <TextInput
              value={templateName}
              onChange={(e) => setTemplateName(e.target.value)}
              sx={{ width: "300px" }}
              placeholder="Template Name"
              data-oid="l4il.m1"
            />

            <Tooltip text="Copy template ID" direction="s" data-oid="ot30hk6">
              <Button
                variant="invisible"
                size="small"
                sx={{
                  padding: "4px",
                  "&:hover": {
                    bg: "accent.subtle",
                  },
                }}
                onClick={() => {
                  const templateId = id;
                  if (templateId) {
                    navigator.clipboard.writeText(templateId);
                    setShowToast(true);
                    setTimeout(() => setShowToast(false), 3000);
                  }
                }}
                leadingVisual={CopyIcon}
                data-oid=":b9n9jc"
              />
            </Tooltip>
            {!showPreview && (
              <Button
                onClick={() => setShowPreview(true)}
                variant="invisible"
                leadingVisual={EyeIcon}
                aria-label="Show Preview"
                data-oid="pabo2px"
              >
                Preview
              </Button>
            )}
            <ButtonGroup data-oid="ogqu92i">
              <Button data-oid="nfo31p6">Cancel</Button>
              <Button variant="default" onClick={handleSave} data-oid="o-ha583">
                Save Template
              </Button>
            </ButtonGroup>
          </Box>
        </Box>

        {/* Main Content Area */}
        <Box
          sx={{
            display: "flex",
            height: "calc(100% - 104px)",
            width: "100%",
            overflow: "hidden",
            p: "20px",
          }}
          data-oid="7l41zpk"
        >
          {/* Left Panel - Tab Content */}
          <Box
            sx={{
              flex: showPreview ? 1 : "auto",
              width: showPreview ? "auto" : "100%",
              height: "100%",
              overflow: "hidden",
              borderRight: showPreview ? "1px solid" : "none",
              borderColor: "border.default",
            }}
            data-oid="1xsitxg"
          >
            {renderTabContent()}
          </Box>

          {/* Right Panel - Preview (Conditionally Visible) */}
          {showPreview && (
            <Box
              sx={{
                width: "50%",
                height: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                pl: 3,
              }}
              data-oid="e62bhf."
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mb: 3,
                  gap: 2,
                  flexDirection: "column",
                  width: "100%",
                }}
                data-oid="axizrhw"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: 2,
                  }}
                  data-oid="ynr2c1z"
                >
                  <Text as="h2" data-oid="x1s7s84">
                    Preview
                  </Text>
                  <Box sx={{ flex: 1 }} data-oid="wx5uxdk" />
                  <Button
                    onClick={handleTestTemplate}
                    variant="primary"
                    size="small"
                    disabled={isTestingTemplate}
                    data-oid="o6t.bky"
                  >
                    {isTestingTemplate ? "Testing..." : "Test Template"}
                  </Button>
                  <Button
                    onClick={() => setShowPreview(false)}
                    variant="invisible"
                    leadingVisual={XIcon}
                    sx={{ color: "fg.muted" }}
                    aria-label="Close Preview"
                    data-oid="qh4sk2z"
                  />
                </Box>

                {/* Test Result Banner */}
                {testResult && (
                  <Box
                    sx={{
                      width: "100%",
                      p: 2,
                      borderRadius: 2,
                      bg: testResult.success
                        ? "success.subtle"
                        : "danger.subtle",
                      color: testResult.success ? "success.fg" : "danger.fg",
                      border: "1px solid",
                      borderColor: testResult.success
                        ? "success.muted"
                        : "danger.muted",
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                    }}
                    data-oid="8q9_ds9"
                  >
                    <Text sx={{ flex: 1 }} data-oid="b9vkj-:">
                      {testResult.message}
                    </Text>
                    {testResult.success && testResult.pdfUrl && (
                      <>
                        <Button
                          size="small"
                          onClick={() =>
                            window.open(testResult.pdfUrl, "_blank")
                          }
                          data-oid="am.s5bm"
                        >
                          View PDF
                        </Button>
                        <Button
                          size="small"
                          variant="invisible"
                          onClick={() => setTestResult(null)}
                          leadingVisual={XIcon}
                          sx={{
                            color: testResult.success
                              ? "success.fg"
                              : "danger.fg",
                          }}
                          aria-label="Close banner"
                          data-oid="r557ek7"
                        />
                      </>
                    )}
                    {!testResult.success && (
                      <Button
                        size="small"
                        variant="invisible"
                        onClick={() => setTestResult(null)}
                        leadingVisual={XIcon}
                        sx={{ color: "danger.fg" }}
                        aria-label="Close banner"
                        data-oid="rznvb47"
                      />
                    )}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  flex: 1,
                  backgroundColor: "white",
                  border: "1px solid",
                  borderColor: "border.default",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
                data-oid="dv9_:5x"
              >
                <iframe
                  srcDoc={previewHTML}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  title="Template Preview"
                  data-oid="pq3n.a."
                />
              </Box>
            </Box>
          )}
        </Box>

        {/* Variable Format Menu */}
        {menuPosition && selectedVariable && (
          <Box
            sx={{
              position: "fixed",
              top: menuPosition.top,
              left: menuPosition.left,
              backgroundColor: "canvas.default",
              borderRadius: 2,
              boxShadow: "shadow.large",
              border: "1px solid",
              borderColor: "border.default",
              zIndex: 9999,
              minWidth: 200,
              transform: "translate(0, 20px)",
            }}
            data-oid="nn8.mxq"
          >
            <ActionList data-oid=".xdv6qa">
              <ActionList.Item
                onSelect={() => applyVariableFormat("date")}
                data-oid="qdw5ctd"
              >
                <ActionList.LeadingVisual data-oid="idu--l3">
                  <CalendarIcon data-oid="62o6-cc" />
                </ActionList.LeadingVisual>
                Format as Date
              </ActionList.Item>
              <ActionList.Item
                onSelect={() => applyVariableFormat("currency")}
                data-oid="b3_t7in"
              >
                <ActionList.LeadingVisual data-oid="7nl:ku:">
                  <TagIcon data-oid="wwe_63w" />
                </ActionList.LeadingVisual>
                Format as Currency
              </ActionList.Item>
              <ActionList.Item
                onSelect={() => applyVariableFormat("barcode")}
                data-oid="hgwi-oq"
              >
                <ActionList.LeadingVisual data-oid="6cg.0_d">
                  <HashIcon data-oid="myuhuh6" />
                </ActionList.LeadingVisual>
                Generate Barcode
              </ActionList.Item>
              <ActionList.Item
                onSelect={() => applyVariableFormat("qrcode")}
                data-oid="d5gqyl3"
              >
                <ActionList.LeadingVisual data-oid="d3r6g2z">
                  <DeviceCameraIcon data-oid="c.lkd-2" />
                </ActionList.LeadingVisual>
                Generate QR Code
              </ActionList.Item>
            </ActionList>
          </Box>
        )}

        {/* Variable Insert Modal */}
        {isVariableModalOpen && (
          <Dialog
            title="Add Template Variable"
            onClose={() => setIsVariableModalOpen(false)}
            width="xlarge"
            footerButtons={[
              {
                content: "Cancel",
                onClick: () => setIsVariableModalOpen(false),
              },
              {
                content: "Insert",
                onClick: () => {
                  if (newVariable && editorRef.current?.view) {
                    const view = editorRef.current.view;
                    const pos = view.state.selection.main.head;
                    view.dispatch({
                      changes: {
                        from: pos,
                        insert: `{{${newVariable}}}`,
                      },
                    });
                    setNewVariable("");
                    setIsVariableModalOpen(false);
                  }
                },
                variant: "medium",
                disabled: !newVariable.trim(),
              },
            ]}
            data-oid="bvzpko3"
          >
            <Box
              sx={{ p: 3, display: "flex", flexDirection: "column", gap: 3 }}
              data-oid="9.tf684"
            >
              <FormControl data-oid="k28lzvb">
                <FormControl.Label data-oid="8bikkya">
                  Variable Name
                </FormControl.Label>
                <TextInput
                  value={newVariable}
                  onChange={(e) => setNewVariable(e.target.value)}
                  placeholder="Enter variable name"
                  block
                  data-oid="fiojitl"
                />
              </FormControl>

              <Box data-oid="gag33:j">
                <FormControl.Label data-oid="2fp4lc.">
                  Mapped Variables
                </FormControl.Label>
                <Box
                  sx={{
                    maxHeight: "200px",
                    overflow: "auto",
                    border: "1px solid",
                    borderColor: "border.default",
                    borderRadius: 2,
                    p: 2,
                  }}
                  data-oid="m-3gen6"
                >
                  {variables.length > 0 ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 2,
                      }}
                      data-oid="fwt.2n9"
                    >
                      {variables.map((variable, index) => (
                        <Box
                          key={index}
                          onClick={() => {
                            if (editorRef.current?.view) {
                              const view = editorRef.current.view;
                              const pos = view.state.selection.main.head;
                              view.dispatch({
                                changes: {
                                  from: pos,
                                  insert: `{{${variable}}}`,
                                },
                              });
                              setIsVariableModalOpen(false);
                            }
                          }}
                          sx={{
                            display: "inline-flex",
                            alignItems: "center",
                            px: 2,
                            py: 1,
                            borderRadius: "2em",
                            bg: "accent.subtle",
                            color: "accent.fg",
                            fontSize: 1,
                            fontWeight: "bold",
                            cursor: "pointer",
                            "&:hover": {
                              bg: "accent.muted",
                            },
                          }}
                          data-oid=".pooj:6"
                        >
                          {variable}
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        py: 4,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "fg.muted",
                      }}
                      data-oid="v4oy7n5"
                    >
                      No mapped variables available
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Dialog>
        )}

        {/* Toast notification - now visible regardless of active tab */}
        {showToast && (
          <Toast message="Template saved successfully!" data-oid="l::yisd" />
        )}
      </Box>
    </DashboardLayout>
  );
}
