import { Box, Text, Button, ButtonGroup, Label, Select } from "@primer/react";
import { DashboardLayout } from "../components/DashboardLayout";
import { CopyIcon } from "@primer/octicons-react";
import { useState, useEffect, useRef } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { useLocation, useNavigate } from "react-router-dom";

interface CodeExample {
  language: string;
  code: string;
  displayName: string;
  icon?: string;
}

function CodeView({ code, language }: { code: string; language: string }) {
  const codeRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (codeRef.current) {
      hljs.highlightElement(codeRef.current);
    }
  }, [code, language]);

  return (
    <Box
      sx={{
        bg: "canvas.default",
        borderRadius: 2,
        overflow: "hidden",
        border: "1px solid",
        borderColor: "border.default",
      }}
      data-oid="ujly6wh"
    >
      {/* Code Header */}
      <Box
        sx={{
          p: 2,
          borderBottom: "1px solid",
          borderColor: "border.default",
          bg: "canvas.subtle",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        data-oid="xh7p5n:"
      >
        <Text
          sx={{
            fontSize: 1,
            fontFamily: "mono",
            color: "fg.muted",
          }}
          data-oid="6mhzbby"
        >
          Response
        </Text>
        <Button
          variant="invisible"
          size="small"
          sx={{ color: "fg.subtle" }}
          onClick={() => navigator.clipboard.writeText(code)}
          data-oid="nhkp6n_"
        >
          <CopyIcon data-oid="4tdv9l." />
        </Button>
      </Box>

      {/* Code Content */}
      <Box
        sx={{
          p: 3,
          overflow: "auto",
          maxHeight: "500px",
        }}
        data-oid="d65psi4"
      >
        <pre
          style={{
            margin: 0,
            padding: 0,
            fontSize: "14px",
            fontFamily: "monospace",
            lineHeight: 1.5,
            whiteSpace: "pre",
            overflowX: "auto",
            backgroundColor: "transparent",
          }}
          data-oid="iy0-2-m"
        >
          <code
            ref={codeRef}
            className={`language-${language}`}
            data-oid="88pq.80"
          >
            {code}
          </code>
        </pre>
      </Box>
    </Box>
  );
}

export default function ApiDocumentation() {
  const [selectedLanguage, setSelectedLanguage] = useState("curl");
  const [selectedResponse, setSelectedResponse] = useState<"success" | "error">(
    "success",
  );
  const location = useLocation();
  const section =
    new URLSearchParams(location.search).get("section") || "generate-pdf";
  const navigate = useNavigate();

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const BASE_URL = "https://app.forgepdf.io";

  const codeExamples: { [key: string]: CodeExample } = {
    curl: {
      language: "bash",
      displayName: "cURL",
      icon: "///",
      code: `# Generate PDF from template
curl -X POST \\
  '${BASE_URL}/api/pdf' \\
  -H 'Authorization: Bearer YOUR_API_KEY' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "template_id": "your_template_id",
    "data": {
      "company_name": "Acme Corp",
      "invoice_number": "INV-001",
      "date": "2024-03-20",
      "items": [
        {
          "description": "Web Development",
          "amount": 1000
        }
      ]
    }
  }'`,
    },
    nodejs: {
      language: "javascript",
      displayName: "Node.js",
      icon: "⬢",
      code: `// Generate PDF from template
const axios = require('axios');
const fs = require('fs');

async function generatePDF() {
  try {
    const response = await axios({
      method: 'post',
      url: '${BASE_URL}/api/pdf',
      headers: {
        'Authorization': 'Bearer YOUR_API_KEY',
        'Content-Type': 'application/json'
      },
      data: {
        template_id: 'your_template_id',
        data: {
          company_name: 'Acme Corp',
          invoice_number: 'INV-001',
          date: '2024-03-20',
          items: [
            {
              description: 'Web Development',
              amount: 1000
            }
          ]
        }
      },
      responseType: 'arraybuffer'
    });

    // Save the PDF
    fs.writeFileSync('document.pdf', response.data);
  } catch (error) {
    console.error('Error:', error.response?.data?.toString() || error.message);
  }
}`,
    },
    ruby: {
      language: "ruby",
      displayName: "Ruby",
      icon: "💎",
      code: `# Generate PDF from template
require 'uri'
require 'net/http'
require 'json'

uri = URI('${BASE_URL}/api/pdf')
http = Net::HTTP.new(uri.host, uri.port)
http.use_ssl = true

request = Net::HTTP::Post.new(uri)
request['Authorization'] = 'Bearer YOUR_API_KEY'
request['Content-Type'] = 'application/json'
request.body = {
  template_id: 'your_template_id',
  data: {
    company_name: 'Acme Corp',
    invoice_number: 'INV-001',
    date: '2024-03-20',
    items: [
      {
        description: 'Web Development',
        amount: 1000
      }
    ]
  }
}.to_json

response = http.request(request)

if response.code == '200'
  # Save the PDF
  File.open('document.pdf', 'wb') { |file| file.write(response.body) }
else
  puts "Error: #{response.body}"
end`,
    },
    python: {
      language: "python",
      displayName: "Python",
      icon: "🐍",
      code: `# Generate PDF from template
import requests

url = "${BASE_URL}/api/pdf"
headers = {
    "Authorization": "Bearer YOUR_API_KEY",
    "Content-Type": "application/json"
}
payload = {
    "template_id": "your_template_id",
    "data": {
        "company_name": "Acme Corp",
        "invoice_number": "INV-001",
        "date": "2024-03-20",
        "items": [
            {
                "description": "Web Development",
                "amount": 1000
            }
        ]
    }
}

response = requests.post(url, json=payload, headers=headers)

if response.status_code == 200:
    # Save the PDF
    with open("document.pdf", "wb") as f:
        f.write(response.content)
else:
    print(f"Error: {response.text}")`,
    },
    java: {
      language: "java",
      displayName: "Java",
      icon: "☕",
      code: `// Generate PDF from template
import java.net.http.HttpClient;
import java.net.http.HttpRequest;
import java.net.http.HttpResponse;
import java.nio.file.Files;
import java.nio.file.Path;

public class GeneratePDF {
    public static void main(String[] args) {
        String url = "${BASE_URL}/api/pdf";
        String requestBody = """
            {
                "template_id": "your_template_id",
                "data": {
                    "company_name": "Acme Corp",
                    "invoice_number": "INV-001",
                    "date": "2024-03-20",
                    "items": [
                        {
                            "description": "Web Development",
                            "amount": 1000
                        }
                    ]
                }
            }
            """;

        try {
            HttpClient client = HttpClient.newHttpClient();
            HttpRequest request = HttpRequest.newBuilder()
                .uri(URI.create(url))
                .header("Authorization", "Bearer YOUR_API_KEY")
                .header("Content-Type", "application/json")
                .POST(HttpRequest.BodyPublishers.ofString(requestBody))
                .build();

            HttpResponse<byte[]> response = client.send(
                request, 
                HttpResponse.BodyHandlers.ofByteArray()
            );

            if (response.statusCode() == 200) {
                // Save the PDF
                Files.write(Path.of("document.pdf"), response.body());
            } else {
                System.out.println("Error: " + new String(response.body()));
            }
        } catch (Exception e) {
            e.printStackTrace();
        }
    }
}`,
    },
    go: {
      language: "go",
      displayName: "Go",
      icon: "🔵",
      code: `// Generate PDF from template
package main

import (
    "bytes"
    "encoding/json"
    "fmt"
    "io/ioutil"
    "net/http"
)

func main() {
    url := "${BASE_URL}/api/pdf"
    
    requestBody, _ := json.Marshal(map[string]interface{}{
        "template_id": "your_template_id",
        "data": map[string]interface{}{
            "company_name": "Acme Corp",
            "invoice_number": "INV-001",
            "date": "2024-03-20",
            "items": []map[string]interface{}{
                {
                    "description": "Web Development",
                    "amount": 1000,
                },
            },
        },
    })

    req, _ := http.NewRequest("POST", url, bytes.NewBuffer(requestBody))
    req.Header.Set("Authorization", "Bearer YOUR_API_KEY")
    req.Header.Set("Content-Type", "application/json")

    client := &http.Client{}
    resp, err := client.Do(req)
    if err != nil {
        panic(err)
    }
    defer resp.Body.Close()

    body, _ := ioutil.ReadAll(resp.Body)

    if resp.StatusCode == 200 {
        // Save the PDF
        ioutil.WriteFile("document.pdf", body, 0644)
    } else {
        fmt.Printf("Error: %s\\n", string(body))
    }
}`,
    },
    csharp: {
      language: "csharp",
      displayName: "C#",
      icon: "#",
      code: `// Generate PDF from template
using System;
using System.Net.Http;
using System.Text;
using System.Text.Json;
using System.Threading.Tasks;
using System.IO;

class Program
{
    static async Task Main()
    {
        var url = "${BASE_URL}/api/pdf";
        var data = new
        {
            template_id = "your_template_id",
            data = new
            {
                company_name = "Acme Corp",
                invoice_number = "INV-001",
                date = "2024-03-20",
                items = new[]
                {
                    new
                    {
                        description = "Web Development",
                        amount = 1000
                    }
                }
            }
        };

        using var client = new HttpClient();
        client.DefaultRequestHeaders.Add("Authorization", "Bearer YOUR_API_KEY");

        var json = JsonSerializer.Serialize(data);
        var content = new StringContent(json, Encoding.UTF8, "application/json");

        var response = await client.PostAsync(url, content);
        
        if (response.IsSuccessStatusCode)
        {
            // Save the PDF
            var pdfBytes = await response.Content.ReadAsByteArrayAsync();
            await File.WriteAllBytesAsync("document.pdf", pdfBytes);
        }
        else
        {
            var error = await response.Content.ReadAsStringAsync();
            Console.WriteLine($"Error: {error}");
        }
    }
}`,
    },
    php: {
      language: "php",
      displayName: "PHP",
      icon: "🐘",
      code: `<?php
# Generate PDF from template
$url = '${BASE_URL}/api/pdf';
$data = array(
    'template_id' => 'your_template_id',
    'data' => array(
        'company_name' => 'Acme Corp',
        'invoice_number' => 'INV-001',
        'date' => '2024-03-20',
        'items' => array(
            array(
                'description' => 'Web Development',
                'amount' => 1000
            )
        )
    )
);

$headers = array(
    'Authorization: Bearer YOUR_API_KEY',
    'Content-Type: application/json'
);

$ch = curl_init();
curl_setopt($ch, CURLOPT_URL, $url);
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode($data));
curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, true);

$response = curl_exec($ch);
$httpCode = curl_getinfo($ch, CURLINFO_HTTP_CODE);

if ($httpCode === 200) {
    # Save the PDF
    file_put_contents('document.pdf', $response);
} else {
    echo 'Error: ' . $response;
}

curl_close($ch);`,
    },
  };

  const responseExamples = {
    success: {
      title: "Example Success Response",
      content: `// Returns PDF file binary content
// Content-Type: application/pdf
// Content-Disposition: attachment; filename=document.pdf`,
    },
    error: {
      title: "Error Response",
      content: `// 401 Unauthorized
{
    "success": false,
    "error": "Unauthorized: Api key is required"
}

// 404 Not Found
{
    "success": false,
    "error": "Template not found"
}

// 500 Internal Server Error
{
    "success": false,
    "error": "PDF Generation Failed: Invalid template format"
}`,
    },
  };

  const urlResponseExamples = {
    success: {
      title: "Example Success Response",
      content: `{
    "success": true,
    "url": "https://app.forgepdf.io/api/download/8DI4HzhrdARK4aDZvSPUW4HDdM33/y4qybOQxax10elP4Vfdk.pdf",
    "error": ""
}`,
    },
    error: {
      title: "Error Response",
      content: `// 401 Unauthorized
{
    "success": false,
    "url": "",
    "error": "Unauthorized: Api key is required"
}

// 404 Not Found
{
    "success": false,
    "url": "",
    "error": "Template not found"
}

// 400 Bad Request
{
    "success": false,
    "url": "",
    "error": "Invalid template data"
}`,
    },
  };

  // Update the urlCodeExamples
  const urlCodeExamples: { [key: string]: CodeExample } = {
    curl: {
      language: "bash",
      displayName: "cURL",
      icon: "///",
      code: `# Generate PDF URL from template
curl -X POST \\
  '${BASE_URL}/api/url' \\
  -H 'Authorization: Bearer YOUR_API_KEY' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "template_id": "your_template_id",
    "data": {
      "company_name": "Acme Corp",
      "invoice_number": "INV-001",
      "date": "2024-03-20",
      "items": [
        {
          "description": "Web Development",
          "amount": 1000
        }
      ]
    }
  }'`,
    },
    // Add other language examples for URL generation...
  };

  return (
    <DashboardLayout data-oid="0p6-im4">
      <Box sx={{ height: "100vh", overflow: "auto", py: 5 }} data-oid="dwxbzns">
        <Box
          sx={{ px: 4, maxWidth: "1600px", margin: "0 auto", pb: 5 }}
          data-oid="3kpk4mn"
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr auto",
              gap: 4,
            }}
            data-oid="rmyg-yr"
          >
            {/* Left Column - Documentation */}
            <Box data-oid="ao9b5a9">
              <Text
                as="h1"
                sx={{ fontSize: 4, fontWeight: "bold", mb: 4 }}
                data-oid="i583259"
              >
                {section === "generate-pdf"
                  ? "Generate PDF File"
                  : "Get Public PDF URL"}
              </Text>

              <Text as="p" sx={{ mb: 4, color: "fg.muted" }} data-oid=".n_nhl0">
                {section === "generate-pdf"
                  ? "Generate and receive a PDF file directly in the API response."
                  : "Generate a PDF and receive a public URL to access the file from our secure storage."}
              </Text>

              <Box sx={{ mb: 4 }} data-oid="pj4aqw_">
                <Text
                  as="h2"
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}
                  data-oid="ov54biw"
                >
                  Base URL
                </Text>
                <Text
                  sx={{ fontFamily: "mono", mb: 2, color: "accent.fg" }}
                  data-oid="i67kdd4"
                >
                  {BASE_URL}
                </Text>
              </Box>

              <Box sx={{ mb: 4 }} data-oid="wt7vhu.">
                <Text
                  as="h2"
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}
                  data-oid="osorzam"
                >
                  Request
                </Text>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 2,
                    p: 2,
                    bg: "canvas.subtle",
                    borderRadius: 2,
                  }}
                  data-oid="43yxdxa"
                >
                  <Label variant="accent" data-oid="y1bphp1">
                    POST
                  </Label>
                  <Text sx={{ fontFamily: "mono" }} data-oid="s4yflx3">
                    {section === "generate-pdf" ? "/api/pdf" : "/api/url"}
                  </Text>
                </Box>
              </Box>

              <Box sx={{ mb: 4 }} data-oid="riq78cc">
                <Text
                  as="h2"
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}
                  data-oid="uceb3nc"
                >
                  Security
                </Text>
                <Box
                  sx={{
                    p: 2,
                    borderRadius: 2,
                    border: "1px solid",
                    borderColor: "border.default",
                  }}
                  data-oid="2qvqdx8"
                >
                  <Text
                    sx={{ fontWeight: "bold", display: "inline-block", mr: 2 }}
                    data-oid="nrs.tga"
                  >
                    🔒 Bearer Auth
                  </Text>
                  <Text sx={{ color: "fg.muted" }} data-oid="ps:e.:0">
                    API key authentication
                  </Text>
                </Box>
              </Box>

              <Box sx={{ mb: 4 }} data-oid="6l4vbqm">
                <Text
                  as="h2"
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}
                  data-oid="53ilbq."
                >
                  Headers
                </Text>
                <Box sx={{ mb: 2 }} data-oid="tq489v_">
                  <Text
                    sx={{
                      fontWeight: "bold",
                      display: "inline-block",
                      mr: 2,
                    }}
                    data-oid="xcpxh:z"
                  >
                    Authorization
                  </Text>
                  <Label
                    variant="danger"
                    sx={{ color: "danger.fg" }}
                    data-oid="lcflnu-"
                  >
                    required
                  </Label>
                  <Text
                    sx={{ color: "fg.muted", display: "block" }}
                    data-oid="jwbkgsw"
                  >
                    Bearer YOUR_API_KEY
                  </Text>
                </Box>
                <Box sx={{ mb: 2 }} data-oid="3vur71w">
                  <Text
                    sx={{
                      fontWeight: "bold",
                      display: "inline-block",
                      mr: 2,
                    }}
                    data-oid="lb.v3-o"
                  >
                    Content-Type
                  </Text>
                  <Label
                    variant="danger"
                    sx={{ color: "danger.fg" }}
                    data-oid="2cy1wvn"
                  >
                    required
                  </Label>
                  <Text
                    sx={{ color: "fg.muted", display: "block" }}
                    data-oid="s36.8fk"
                  >
                    application/json
                  </Text>
                </Box>
              </Box>

              <Box sx={{ mb: 4 }} data-oid="g_6p:.7">
                <Text
                  as="h2"
                  sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}
                  data-oid="2.b68uq"
                >
                  Request Body
                </Text>
                <Box sx={{ mb: 2 }} data-oid="ovz0_m8">
                  <Text
                    sx={{
                      fontWeight: "bold",
                      display: "inline-block",
                      mr: 2,
                    }}
                    data-oid="ztd2y:y"
                  >
                    template_id
                  </Text>
                  <Label variant="attention" sx={{ mr: 2 }} data-oid="ela9gfc">
                    string
                  </Label>
                  <Label
                    variant="danger"
                    sx={{ color: "danger.fg" }}
                    data-oid="bpwdaj1"
                  >
                    required
                  </Label>
                  <Text
                    sx={{ color: "fg.muted", display: "block" }}
                    data-oid="0iu7dwr"
                  >
                    The ID of your template
                  </Text>
                </Box>
                <Box data-oid="0rle18-">
                  <Text
                    sx={{
                      fontWeight: "bold",
                      display: "inline-block",
                      mr: 2,
                    }}
                    data-oid="vggr50m"
                  >
                    data
                  </Text>
                  <Label variant="attention" sx={{ mr: 2 }} data-oid="2me-auc">
                    object
                  </Label>
                  <Label
                    variant="danger"
                    sx={{ color: "danger.fg" }}
                    data-oid="3wg95b-"
                  >
                    required
                  </Label>
                  <Text
                    sx={{ color: "fg.muted", display: "block" }}
                    data-oid="627mdu6"
                  >
                    The data to be used in the template
                  </Text>
                </Box>
              </Box>
            </Box>

            {/* Right Column - Code Examples */}
            <Box
              sx={{
                bg: "canvas.subtle",
                borderRadius: 2,
                p: 4,
                height: "fit-content",
                position: "sticky",
                top: 5,
                width: "600px",
              }}
              data-oid="ksbf077"
            >
              {/* Use different examples based on the section */}
              <CodeView
                code={
                  section === "generate-pdf"
                    ? codeExamples[selectedLanguage].code
                    : urlCodeExamples[selectedLanguage].code
                }
                language={
                  section === "generate-pdf"
                    ? codeExamples[selectedLanguage].language
                    : urlCodeExamples[selectedLanguage].language
                }
                data-oid="_.jnqd9"
              />

              {/* Response Examples */}
              <Box
                sx={{
                  mt: 5,
                  mb: 3,
                  display: "inline-block",
                  border: "1px solid",
                  borderColor: "border.default",
                  borderRadius: 2,
                  overflow: "hidden",
                }}
                data-oid="tem88gy"
              >
                <ButtonGroup data-oid="jiiiags">
                  <Button
                    onClick={() => setSelectedResponse("success")}
                    aria-selected={selectedResponse === "success"}
                    sx={{
                      border: "none",
                      borderRadius: 0,
                      borderRight: "1px solid",
                      borderColor: "border.default",
                      bg:
                        selectedResponse === "success"
                          ? "canvas.subtle"
                          : "canvas.default",
                      color:
                        selectedResponse === "success"
                          ? "success.fg"
                          : "inherit",
                      fontWeight:
                        selectedResponse === "success" ? "bold" : "normal",
                    }}
                    data-oid="gx9kxvy"
                  >
                    Example Success Response
                  </Button>
                  <Button
                    onClick={() => setSelectedResponse("error")}
                    aria-selected={selectedResponse === "error"}
                    sx={{
                      border: "none",
                      borderRadius: 0,
                      bg:
                        selectedResponse === "error"
                          ? "canvas.subtle"
                          : "canvas.default",
                      color:
                        selectedResponse === "error" ? "danger.fg" : "inherit",
                      fontWeight:
                        selectedResponse === "error" ? "bold" : "normal",
                    }}
                    data-oid=":j396l1"
                  >
                    Error Response
                  </Button>
                </ButtonGroup>
              </Box>

              {/* Response Content */}
              <CodeView
                code={
                  section === "generate-pdf"
                    ? responseExamples[selectedResponse].content
                    : urlResponseExamples[selectedResponse].content
                }
                language="json"
                data-oid="j80wcqn"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
