import { useState, useEffect } from "react";
import { Box, Text, Button, Flash, Spinner, Dialog, ButtonGroup } from "@primer/react";
import { ArrowLeftIcon, ChevronLeftIcon, ChevronRightIcon } from "@primer/octicons-react";
import { collection, query, where, getDocs, doc, orderBy, limit, startAfter, endBefore, limitToLast, QueryDocumentSnapshot } from "firebase/firestore";
import { db } from "../config/firebase";
import { DashboardLayout } from "../components/DashboardLayout";
import { useParams, useNavigate } from "react-router-dom";
import { formatDistanceToNow } from "date-fns";

interface ApiUsageLog {
  id: string;
  status: string;
  error: string;
  created_at: any;
  template_id: string;
  dynamic_values: any;
  api_key_ref: any;
}

export default function ApiUsage() {
  const { apiKeyId } = useParams();
  const navigate = useNavigate();
  const [logs, setLogs] = useState<ApiUsageLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedLog, setSelectedLog] = useState<ApiUsageLog | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  
  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [pageSnapshots, setPageSnapshots] = useState<QueryDocumentSnapshot[]>([]);
  const PAGE_SIZE = 50;

  const loadLogs = async (direction: 'next' | 'prev' | 'initial' = 'initial') => {
    try {
      setLoading(true);
      const apiKeyRef = doc(db, "api_keys", apiKeyId!);
      let baseQuery = query(
        collection(db, "api_key_history"),
        where("api_key_ref", "==", apiKeyRef),
        orderBy("created_at", "desc")
      );

      let paginatedQuery;
      if (direction === 'next' && pageSnapshots.length > 0) {
        const lastVisible = pageSnapshots[pageSnapshots.length - 1];
        paginatedQuery = query(baseQuery, startAfter(lastVisible), limit(PAGE_SIZE + 1));
      } else if (direction === 'prev' && pageSnapshots.length > 0) {
        const firstVisible = pageSnapshots[0];
        paginatedQuery = query(baseQuery, endBefore(firstVisible), limitToLast(PAGE_SIZE + 1));
      } else {
        paginatedQuery = query(baseQuery, limit(PAGE_SIZE + 1));
      }

      const querySnapshot = await getDocs(paginatedQuery);
      const documents = querySnapshot.docs;

      // Check if we have more pages
      const hasMore = documents.length > PAGE_SIZE;
      // Remove the extra item we used to check for more pages
      const actualDocs = hasMore ? documents.slice(0, PAGE_SIZE) : documents;

      if (direction === 'prev') {
        actualDocs.reverse();
      }

      const loadedLogs = actualDocs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as ApiUsageLog[];

      setLogs(loadedLogs);
      setPageSnapshots(actualDocs);
      
      // Update pagination states
      if (direction === 'next' && hasMore) {
        setHasNextPage(true);
        setHasPrevPage(true);
        setCurrentPage(prev => prev + 1);
      } else if (direction === 'prev') {
        const prevQuerySnapshot = await getDocs(
          query(baseQuery, endBefore(actualDocs[0]), limitToLast(1))
        );
        setHasPrevPage(!prevQuerySnapshot.empty);
        setHasNextPage(true);
        setCurrentPage(prev => prev - 1);
      } else {
        // Initial load
        setHasNextPage(hasMore);
        setHasPrevPage(false);
        setCurrentPage(1);
      }

    } catch (err) {
      console.error("Error loading logs:", err);
      setError("Failed to load API usage logs");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadLogs('initial');
  }, [apiKeyId]);

  return (
    <DashboardLayout>
      <Box sx={{ p: "20px" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: 3,
            gap: 3,
          }}
        >
          <Button
            onClick={() => navigate(-1)}
            variant="invisible"
            leadingVisual={ArrowLeftIcon}
            sx={{
              color: "fg.muted",
              p: "6px",
              "&:hover": { color: "fg.default" },
            }}
          >
            Back
          </Button>
          <Text as="h2" sx={{ fontSize: 4, fontWeight: "bold" }}>
            API Usage Logs
          </Text>
        </Box>

        {error && (
          <Flash variant="danger" sx={{ mb: 3 }}>
            {error}
          </Flash>
        )}

        <Box
          sx={{
            height: "calc(100vh - 84px)",
            minHeight: "200px",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
                gap: 3,
              }}
            >
              <Spinner size="large" />
              <Text sx={{ color: "fg.muted" }}>
                Loading API usage logs...
              </Text>
            </Box>
          ) : logs.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flex: 1,
              }}
            >
              <Text>
                No usage logs found for this API key.
              </Text>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "auto",
                width: "100%",
                py: "20px",
                pb: "80px",
              }}
            >
              <Box as="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
                {logs.map((log) => (
                  <Box
                    key={log.id}
                    onClick={() => {
                      setSelectedLog(log);
                      setIsDialogOpen(true);
                    }}
                    sx={{
                      p: 3,
                      bg: "canvas.subtle",
                      borderRadius: 2,
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      cursor: "pointer",
                      border: "1px solid",
                      borderColor: "border.default",
                      mb: 2,
                      "&:hover": {
                        borderColor: "accent.emphasis",
                        boxShadow: "shadow.medium",
                      },
                      "&:last-child": {
                        mb: 0,
                      },
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                          mb: 1,
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: 2,
                            fontWeight: "bold",
                            color: "fg.default",
                          }}
                        >
                          Template ID: {log.template_id}
                        </Text>
                        <Text
                          sx={{
                            fontSize: 1,
                            px: 2,
                            py: 1,
                            borderRadius: 2,
                            bg:
                              log.status === "Done"
                                ? "success.subtle"
                                : "danger.subtle",
                            color:
                              log.status === "Done"
                                ? "success.fg"
                                : "danger.fg",
                          }}
                        >
                          {log.status}
                        </Text>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 1,
                        }}
                      >
                        <Text
                          sx={{
                            fontSize: 0,
                            color: "fg.muted",
                          }}
                        >
                          {formatDistanceToNow(log.created_at.toDate())} ago
                        </Text>
                        {log.error && (
                          <Text
                            sx={{
                              fontSize: 0,
                              color: "danger.fg",
                              fontFamily: "mono",
                            }}
                          >
                            Error: {log.error}
                          </Text>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}
              </Box>

              {/* Pagination Controls */}
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4, gap: 3 }}>
                <ButtonGroup>
                  <Button
                    disabled={!hasPrevPage}
                    onClick={() => loadLogs('prev')}
                    leadingVisual={ChevronLeftIcon}
                  >
                    Previous
                  </Button>
                  <Button
                    disabled={!hasNextPage}
                    onClick={() => loadLogs('next')}
                    trailingVisual={ChevronRightIcon}
                  >
                    Next
                  </Button>
                </ButtonGroup>
                <Text sx={{ color: 'fg.muted', fontSize: 1 }}>
                  Page {currentPage}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>

      {/* Log Details Dialog */}
      {isDialogOpen && selectedLog && (
        <Dialog
          title="API Usage Log Details"
          onClose={() => {
            setIsDialogOpen(false);
            setSelectedLog(null);
          }}
        >
          <Box sx={{ p: 3 }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
              <Box>
                <Text sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}>
                  Main Information
                </Text>
                <Box sx={{ display: "grid", gridTemplateColumns: "200px 1fr", gap: 2 }}>
                  <Text sx={{ color: "fg.muted" }}>Status:</Text>
                  <Text sx={{ color: selectedLog.status === "Done" ? "success.fg" : "danger.fg", fontWeight: "bold" }}>
                    {selectedLog.status}
                  </Text>

                  <Text sx={{ color: "fg.muted" }}>Template ID:</Text>
                  <Text sx={{ fontFamily: "mono" }}>{selectedLog.template_id}</Text>

                  <Text sx={{ color: "fg.muted" }}>Created At:</Text>
                  <Text>{selectedLog.created_at.toDate().toLocaleString()}</Text>

                  {selectedLog.error && (
                    <>
                      <Text sx={{ color: "fg.muted" }}>Error:</Text>
                      <Text sx={{ color: "danger.fg", fontFamily: "mono" }}>
                        {selectedLog.error}
                      </Text>
                    </>
                  )}
                </Box>
              </Box>

              {selectedLog.dynamic_values && (
                <Box>
                  <Text sx={{ fontSize: 2, fontWeight: "bold", mb: 2 }}>
                    Dynamic Values
                  </Text>
                  <Box sx={{ display: "grid", gridTemplateColumns: "200px 1fr", gap: 2 }}>
                    {Object.entries(selectedLog.dynamic_values).map(([key, value]) => (
                      <>
                        <Text sx={{ color: "fg.muted" }}>{key}:</Text>
                        <Text sx={{ fontFamily: "mono" }}>
                          {typeof value === 'object' ? JSON.stringify(value) : String(value)}
                        </Text>
                      </>
                    ))}
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Dialog>
      )}
    </DashboardLayout>
  );
}
