import { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Textarea,
  FormControl,
  Spinner,
} from "@primer/react";
import { DashboardLayout } from "../components/DashboardLayout";
import { useNavigate } from "react-router-dom";
import {
  ArrowLeftIcon,
  PaperclipIcon,
  XIcon,
  ArrowUpIcon,
} from "@primer/octicons-react";
import { useAuth } from "../contexts/AuthContext";
import { db } from "../config/firebase";
import {
  collection,
  addDoc,
  doc,
  Timestamp,
  query,
  where,
  limit,
  getDocs,
} from "firebase/firestore";
import OpenAI from "openai";

interface AttachedFile {
  file: File;
  preview: string;
  base64: string;
}

type MessageRole = "user" | "assistant";

type MessageContent =
  | {
      type: "text";
      text: {
        value: string;
        annotations: any[];
      };
    }
  | {
      type: "image_file";
      image_file: {
        file_id: string;
      };
    };

type MessageContentPart =
  | {
      type: "text";
      text: string;
    }
  | {
      type: "image_url";
      image_url: {
        url: string;
        detail: "low" | "high" | "auto";
      };
    };

const formatHTML = (html: string): string => {
  // Define indent level
  let formatted = "";
  let indent = 0;

  // Split html into array of tags
  const tags = html.split(/(<\/?[^>]+>)/g);

  // Process each tag
  tags.forEach((tag) => {
    // Skip empty tags
    if (!tag.trim()) return;

    // Check if closing tag
    if (tag.startsWith("</")) {
      indent--;
      formatted += "  ".repeat(indent) + tag + "\n";
    }
    // Check if self-closing tag
    else if (tag.endsWith("/>")) {
      formatted += "  ".repeat(indent) + tag + "\n";
    }
    // Check if opening tag
    else if (tag.startsWith("<")) {
      formatted += "  ".repeat(indent) + tag + "\n";
      // Don't indent for inline elements
      if (
        !tag.startsWith("<br") &&
        !tag.startsWith("<img") &&
        !tag.startsWith("<input")
      ) {
        indent++;
      }
    }
    // Text content
    else {
      formatted += "  ".repeat(indent) + tag.trim() + "\n";
    }
  });

  return formatted;
};

export default function TemplatePrompt() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [prompt, setPrompt] = useState("");
  const [attachedFiles, setAttachedFiles] = useState<AttachedFile[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [apiKey, setApiKey] = useState<string | null>(null);

  const suggestions = [
    "Generate an invoice",
    "Need a 2 page contract for outsourcing my mobile app development",
    "Need a A6 letter where i can have sender name and receiver",
  ];

  // Load API key on component mount
  useEffect(() => {
    const loadApiKey = async () => {
      if (!user) return;

      const userRef = doc(db, "users", user.uid);
      const apiKeysQuery = query(
        collection(db, "api_keys"),
        where("user_ref", "==", userRef),
        limit(1),
      );

      const apiKeysSnapshot = await getDocs(apiKeysQuery);
      if (!apiKeysSnapshot.empty) {
        setApiKey(apiKeysSnapshot.docs[0].data().api_key);
      }
    };

    loadApiKey();
  }, [user]);

  const handleFileAttach = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const fileList = event.target.files;
    if (!fileList) return;

    const newFiles: AttachedFile[] = [];
    const totalFiles = attachedFiles.length + fileList.length;

    if (totalFiles > 5) {
      setError("Maximum 5 images allowed");
      return;
    }

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];

      if (!file.type.startsWith("image/")) {
        setError("Only image files are allowed");
        return;
      }

      if (file.size > 20 * 1024 * 1024) {
        setError("Image size must be less than 20MB");
        return;
      }

      try {
        const base64 = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            if (typeof reader.result === "string") {
              resolve(reader.result);
            }
          };
          reader.onerror = reject;
        });

        newFiles.push({
          file,
          preview: URL.createObjectURL(file),
          base64,
        });
      } catch (error) {
        console.error("Error processing file:", error);
        setError("Failed to process image");
        return;
      }
    }

    setAttachedFiles((prev) => [...prev, ...newFiles]);
    setError(null);
  };

  const generateTemplate = async () => {
    if (!apiKey) {
      setError("No API key found. Please generate an API key first.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        "https://generatetemplate-stocfzcgjq-uc.a.run.app",
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt,
            imageBase64: attachedFiles[0]?.base64,
          }),
        },
      );

      if (!response.ok) {
        throw new Error(await response.text());
      }

      const responseData = await response.json();

      console.log("Creating template document...");
      const userRef = doc(db, "users", user!.uid);
      const now = Timestamp.now();

      const newTemplate = {
        name: responseData.name || "New Template",
        html: responseData.html,
        data_json: JSON.stringify(responseData.sampleData, null, 2),
        variables: responseData.variables,
        config: {
          margine_bottom: 4,
          margine_left: 4,
          margine_right: 4,
          margine_top: 4,
          orientation: "Portrait",
          paper_size: "Letter",
          print_background: true,
        },
        user_ref: userRef,
        created_at: now,
        updated_at: now,
      };

      console.log("Saving template to database...");
      const docRef = await addDoc(collection(db, "templates"), newTemplate);
      console.log("Template saved:", docRef.id);

      navigate(`/dashboard/templates/edit/${docRef.id}`);
    } catch (err) {
      console.error("Error in template generation:", err);
      setError(
        err instanceof Error ? err.message : "Failed to generate template",
      );
    } finally {
      setLoading(false);
    }
  };

  const handlePaste = async (e: React.ClipboardEvent) => {
    const items = e.clipboardData.items;
    const imageItems = Array.from(items).filter((item) =>
      item.type.startsWith("image/"),
    );

    if (imageItems.length > 0) {
      e.preventDefault(); // Prevent default paste behavior for images

      if (attachedFiles.length >= 5) {
        setError("Maximum 5 images allowed");
        return;
      }

      for (const item of imageItems) {
        const file = item.getAsFile();
        if (!file) continue;

        if (file.size > 20 * 1024 * 1024) {
          setError("Image size must be less than 20MB");
          return;
        }

        try {
          const base64 = await new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              if (typeof reader.result === "string") {
                resolve(reader.result);
              }
            };
            reader.onerror = reject;
          });

          setAttachedFiles((prev) => [
            ...prev,
            {
              file,
              preview: URL.createObjectURL(file),
              base64,
            },
          ]);
          setError(null);
        } catch (error) {
          console.error("Error processing pasted image:", error);
          setError("Failed to process image");
        }
      }
    }
  };

  return (
    <DashboardLayout data-oid="er640aa">
      <Box
        sx={{
          p: "20px",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}
        data-oid="mc0wukw"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            mb: 3,
            gap: 3,
          }}
          data-oid="5o12.zo"
        >
          <Button
            onClick={() => navigate(-1)}
            variant="invisible"
            leadingVisual={ArrowLeftIcon}
            sx={{
              color: "fg.muted",
              p: "6px",
              "&:hover": { color: "fg.default" },
            }}
            data-oid="zevzvo1"
          >
            Back
          </Button>
          <Text
            as="h2"
            sx={{ fontSize: 4, fontWeight: "bold" }}
            data-oid="cmoj8l7"
          >
            Create Template with AI
          </Text>
        </Box>

        <Box
          sx={{
            maxWidth: "800px",
            mx: "auto",
            px: 3,
            width: "100%",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          data-oid="5uhc0ob"
        >
          <Text
            as="h1"
            sx={{
              fontSize: 48,
              fontWeight: "bold",
              mb: 3,
              textAlign: "center",
              color: "fg.default",
            }}
            data-oid="-g_sfwk"
          >
            What do you need?
          </Text>

          <Box
            sx={{
              bg: "canvas.default",
              p: 3,
              borderRadius: 2,
              border: "1px solid",
              borderColor: "border.default",
            }}
            data-oid="mt3pf8e"
          >
            {attachedFiles.length > 0 && (
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                }}
                data-oid="luxl42:"
              >
                {attachedFiles.map((file, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      bg: "canvas.subtle",
                      p: 2,
                      borderRadius: 2,
                      width: "fit-content",
                    }}
                    data-oid="v084wpo"
                  >
                    <Box
                      sx={{
                        width: "40px",
                        height: "40px",
                        borderRadius: 2,
                        overflow: "hidden",
                        border: "1px solid",
                        borderColor: "border.default",
                      }}
                      data-oid="2.b9slz"
                    >
                      <img
                        src={file.preview}
                        alt="Preview"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        }}
                        data-oid="l7uzap8"
                      />
                    </Box>
                    <Text data-oid="c8g:c8k">{file.file.name}</Text>
                    <Button
                      variant="invisible"
                      size="small"
                      onClick={() => {
                        setAttachedFiles((prev) =>
                          prev.filter((_, i) => i !== index),
                        );
                      }}
                      sx={{ p: 1 }}
                      data-oid="jn.633f"
                    >
                      <XIcon data-oid="n-b:ik4" />
                    </Button>
                  </Box>
                ))}
              </Box>
            )}

            <Box sx={{ position: "relative", mb: 2 }} data-oid="q7prn1a">
              <Textarea
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                onPaste={handlePaste}
                placeholder="Describe what you want to generate"
                rows={3}
                sx={{
                  width: "100%",
                  resize: "none",
                  minHeight: "unset",
                }}
                data-oid="yx_4vdo"
              />
            </Box>

            <Box
              sx={{ display: "flex", gap: 2, alignItems: "center" }}
              data-oid="jmpj7f5"
            >
              <Button
                as="label"
                htmlFor="file-input"
                variant="default"
                leadingVisual={PaperclipIcon}
                sx={{ cursor: "pointer" }}
                disabled={loading || attachedFiles.length >= 5}
                data-oid="qbukbxc"
              >
                Attach Images ({attachedFiles.length}/5)
                <input
                  type="file"
                  id="file-input"
                  onChange={handleFileAttach}
                  accept="image/*"
                  multiple
                  style={{ display: "none" }}
                  data-oid="y3fj-a9"
                />
              </Button>
              <Box sx={{ flex: 1 }} data-oid="3c_til4" />
              <Button
                variant="primary"
                disabled={(!prompt.trim() && !attachedFiles.length) || loading}
                leadingVisual={loading ? Spinner : ArrowUpIcon}
                onClick={generateTemplate}
                data-oid="e5l1xi0"
              >
                {loading ? "Generating..." : "Generate"}
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              mt: 3,
              display: "flex",
              gap: 2,
              justifyContent: "center",
              flexWrap: "wrap",
            }}
            data-oid="3bgnq5u"
          >
            {suggestions.map((suggestion: string, index: number) => (
              <Button
                key={index}
                variant="invisible"
                onClick={() => setPrompt(suggestion)}
                sx={{
                  border: "1px solid",
                  borderColor: "border.default",
                  borderRadius: 2,
                  p: "8px 12px",
                  "&:hover": {
                    bg: "canvas.subtle",
                  },
                }}
                data-oid="tag8lx2"
              >
                {suggestion} →
              </Button>
            ))}
          </Box>
        </Box>
      </Box>

      {error && (
        <Box
          sx={{
            mt: 3,
            p: 2,
            borderRadius: 2,
            bg: "danger.subtle",
            color: "danger.fg",
            fontSize: 1,
          }}
          data-oid="9.0.n2i"
        >
          {error}
        </Box>
      )}
    </DashboardLayout>
  );
}
