import React, { useState } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { html } from "@codemirror/lang-html";
import { vscodeLight } from "@uiw/codemirror-theme-vscode";
import styled from "styled-components";
import { Button } from "@primer/react";
import { useNavigate } from "react-router-dom";

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

const Header = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  background: #f5f5f5;
  border-bottom: 1px solid #e1e4e8;

  h2 {
    color: #24292e;
    margin: 0;
  }
`;

const MainContainer = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const EditorContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow: auto;
  background: #ffffff;

  .cm-editor {
    border-radius: 6px;
    overflow: hidden;
    height: calc(100vh - 100px);
    background: #ffffff !important;
  }

  .cm-scroller {
    padding: 16px 0;
  }

  .cm-gutters {
    background: #f5f5f5 !important;
    border-right: 1px solid #e1e4e8 !important;
  }

  .cm-activeLineGutter {
    background: #e8eaed !important;
  }

  .cm-line {
    color: #24292e !important;
  }
`;

const PreviewContainer = styled.div`
  flex: 1;
  padding: 20px;
  overflow: auto;
  background: #ffffff;
  border-left: 1px solid #e1e4e8;

  iframe {
    width: 100%;
    height: calc(100vh - 100px);
    border: 1px solid #e1e4e8;
    border-radius: 6px;
    background: #ffffff;
  }
`;

const VisualEditor = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>(`<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>My HTML Document</title>
</head>
<body>
    <h1>Welcome to HTML Editor</h1>
    <p>Start editing your HTML here...</p>
</body>
</html>`);

  const onChange = React.useCallback((value: string) => {
    setCode(value);
  }, []);

  const updatePreview = () => {
    const iframe = document.getElementById("preview") as HTMLIFrameElement;
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.document.open();
      iframe.contentWindow.document.write(code);
      iframe.contentWindow.document.close();
    }
  };

  React.useEffect(() => {
    updatePreview();
  }, [code]);

  return (
    <Wrapper data-oid="sc4sqx6">
      <Header data-oid="0prt0u3">
        <Button
          onClick={() => navigate("/dashboard/templates")}
          data-oid="2ssyt:r"
        >
          ← Back
        </Button>
        <h2 data-oid="_k_bn0n">HTML Editor</h2>
      </Header>
      <MainContainer data-oid="ceb-8x7">
        <EditorContainer data-oid="1_80q6g">
          <CodeMirror
            value={code}
            height="100%"
            theme={vscodeLight}
            extensions={[html()]}
            onChange={onChange}
            basicSetup={{
              lineNumbers: true,
              highlightActiveLineGutter: true,
              highlightSpecialChars: true,
              history: true,
              foldGutter: true,
              drawSelection: true,
              dropCursor: true,
              allowMultipleSelections: true,
              indentOnInput: true,
              syntaxHighlighting: true,
              bracketMatching: true,
              closeBrackets: true,
              autocompletion: true,
              rectangularSelection: true,
              crosshairCursor: true,
              highlightActiveLine: true,
              highlightSelectionMatches: true,
              closeBracketsKeymap: true,
              defaultKeymap: true,
              searchKeymap: true,
              historyKeymap: true,
              foldKeymap: true,
              completionKeymap: true,
              lintKeymap: true,
            }}
            data-oid="ftu7qhd"
          />
        </EditorContainer>
        <PreviewContainer data-oid="gjvjc11">
          <iframe
            id="preview"
            title="Preview"
            sandbox="allow-same-origin allow-scripts"
            data-oid=":z751i9"
          />
        </PreviewContainer>
      </MainContainer>
    </Wrapper>
  );
};

export default VisualEditor;
