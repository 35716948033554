import { Box, Text, Button, ProgressBar, Spinner } from "@primer/react";
import { DashboardLayout } from "../components/DashboardLayout";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { collection, query, where, getDocs, doc } from "firebase/firestore";
import { db } from "../config/firebase";
import {
  KeyIcon,
  FileIcon,
  CreditCardIcon,
  ArrowRightIcon,
} from "@primer/octicons-react";
import { LogoTextShape } from "../assets";
import socialImage from "../assets/images/social.jpg";
import { useWallet } from "../hooks/useWallet";

interface DashboardStats {
  apiKeysCount: number;
  templatesCount: number;
  walletBalance: number;
  freeUsage: {
    used: number;
    total: number;
  };
  tokens: {
    used: number;
    available: number;
  };
}

export default function Dashboard() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { walletData, loading: walletLoading } = useWallet();
  const [stats, setStats] = useState<DashboardStats>({
    apiKeysCount: 0,
    templatesCount: 0,
    walletBalance: 0,
    freeUsage: {
      used: walletData?.free_usage || 0,
      total: walletData?.free_usage_limit || 1000,
    },
    tokens: {
      used: walletData?.token_usage || 0,
      available: walletData?.available_tokens || 0,
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStats = async () => {
      if (!user) return;

      try {
        const userRef = doc(db, "users", user.uid);

        // Get API Keys count
        const apiKeysQuery = query(
          collection(db, "api_keys"),
          where("user_ref", "==", userRef),
        );
        const apiKeysSnapshot = await getDocs(apiKeysQuery);

        // Get Templates count
        const templatesQuery = query(
          collection(db, "templates"),
          where("user_ref", "==", userRef),
        );
        const templatesSnapshot = await getDocs(templatesQuery);

        setStats((prev) => ({
          ...prev,
          apiKeysCount: apiKeysSnapshot.size,
          templatesCount: templatesSnapshot.size,
        }));
      } catch (error) {
        console.error("Error loading dashboard stats:", error);
      } finally {
        setLoading(false);
      }
    };

    loadStats();
  }, [user]);

  useEffect(() => {
    if (walletData) {
      setStats((prev) => ({
        ...prev,
        walletBalance: walletData.amount,
        freeUsage: {
          used: walletData.free_usage,
          total: walletData.free_usage_limit,
        },
        tokens: {
          used: walletData.token_usage,
          available: walletData.available_tokens,
        },
      }));
    }
  }, [walletData]);

  const calculatePercentage = (used: number, total: number) => {
    return (used / total) * 100;
  };

  return (
    <DashboardLayout data-oid="tqcaoz5">
      <Box sx={{ p: 4 }} data-oid="8.t527g">
        {/* Updated Welcome Section */}
        <Box
          sx={{
            bg: "canvas.default",
            border: "1px solid",
            borderColor: "border.default",
            borderRadius: 2,
            overflow: "hidden",
            mb: 4,
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${socialImage})`,
              backgroundSize: "contain",
              backgroundPosition: "right center",
              backgroundRepeat: "no-repeat",
              opacity: 0.5,
              zIndex: 0,
            },
            "&::after": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background:
                "linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 40%, transparent 70%)",
              zIndex: 1,
            },
            zIndex: 0,
          }}
          data-oid="_s6wq.v"
        >
          <Box
            sx={{
              px: 4,
              py: 5,
              display: "flex",
              alignItems: "center",
              minHeight: "320px",
              position: "relative",
              zIndex: 2,
              maxWidth: "1400px",
              mx: "auto",
            }}
            data-oid="7m7yxcz"
          >
            {/* Text Content - Constrained to left half */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 3,
                width: "50%", // Added to constrain width
                pr: 4, // Added padding to keep text away from gradient
              }}
              data-oid="tobsso4"
            >
              <Text
                sx={{
                  fontSize: 1,
                  color: "fg.muted",
                }}
                data-oid="pxa6_38"
              >
                Welcome back
              </Text>
              <Text
                as="h1"
                sx={{
                  fontSize: 6,
                  fontWeight: "bold",
                  color: "fg.default",
                  lineHeight: "1.25",
                  mb: 2,
                }}
                data-oid="ifu:gjz"
              >
                {user?.displayName}
              </Text>
              <Text
                sx={{
                  fontSize: 2,
                  color: "fg.muted",
                  lineHeight: 1.5,
                }}
                data-oid=":43tpvi"
              >
                Here's an overview of your account and recent activity. Manage
                your API keys, templates, and monitor your usage all in one
                place.
              </Text>
            </Box>
          </Box>
        </Box>

        {/* Stats Grid */}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: 4,
            mb: 4,
          }}
          data-oid="c91hzcx"
        >
          {/* API Keys Card */}
          <Box
            sx={{
              p: 4,
              border: "1px solid",
              borderColor: "border.default",
              borderRadius: 2,
              bg: "canvas.subtle",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            data-oid="go6gsdl"
          >
            <Box sx={{ mb: 3 }} data-oid="_738v6j">
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}
                data-oid="-hik6:a"
              >
                <KeyIcon size={16} data-oid="6gkxn:x" />
                <Text
                  sx={{ fontSize: 2, fontWeight: "bold" }}
                  data-oid=".1ligdx"
                >
                  API Keys
                </Text>
              </Box>
              <Text sx={{ fontSize: 4, fontWeight: "bold" }} data-oid="33vccin">
                {stats.apiKeysCount}
              </Text>
            </Box>
            <Box
              sx={{ mt: "auto", display: "flex", justifyContent: "flex-end" }}
              data-oid="o4yy2sg"
            >
              <Button
                onClick={() => navigate("/dashboard/api-keys")}
                variant="default"
                trailingVisual={ArrowRightIcon}
                data-oid="m9q4ntk"
              >
                Manage API Keys
              </Button>
            </Box>
          </Box>

          {/* Templates Card */}
          <Box
            sx={{
              p: 4,
              border: "1px solid",
              borderColor: "border.default",
              borderRadius: 2,
              bg: "canvas.subtle",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            data-oid="v-ha710"
          >
            <Box sx={{ mb: 3 }} data-oid="oq_q6vy">
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}
                data-oid="gpbzpnw"
              >
                <FileIcon size={16} data-oid="751y-od" />
                <Text
                  sx={{ fontSize: 2, fontWeight: "bold" }}
                  data-oid="l.z1m-."
                >
                  Templates
                </Text>
              </Box>
              <Text sx={{ fontSize: 4, fontWeight: "bold" }} data-oid="5t.rj-z">
                {stats.templatesCount}
              </Text>
            </Box>
            <Box
              sx={{ mt: "auto", display: "flex", justifyContent: "flex-end" }}
              data-oid="wm4z8bd"
            >
              <Button
                onClick={() => navigate("/dashboard/templates")}
                variant="default"
                trailingVisual={ArrowRightIcon}
                data-oid="8-xjxg7"
              >
                Manage Templates
              </Button>
            </Box>
          </Box>

          {/* Wallet & Usage Card */}
          <Box
            sx={{
              p: 4,
              border: "1px solid",
              borderColor: "border.default",
              borderRadius: 2,
              bg: "canvas.subtle",
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
            data-oid="5v:y183"
          >
            <Box sx={{ mb: 3 }} data-oid="7xhqb8n">
              <Box
                sx={{ display: "flex", alignItems: "center", gap: 2, mb: 3 }}
                data-oid="lp-r7.j"
              >
                <CreditCardIcon size={16} data-oid="kt.w.fj" />
                <Text
                  sx={{ fontSize: 2, fontWeight: "bold" }}
                  data-oid="5.:13xj"
                >
                  Usage & Billing
                </Text>
              </Box>

              {/* Free Usage */}
              <Box sx={{ mb: 3 }} data-oid="zg.0qrd">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                  data-oid="mlcqlxj"
                >
                  <Text sx={{ fontSize: 1 }} data-oid="-pv:2th">
                    Free Usage
                  </Text>
                  <Text sx={{ fontSize: 1 }} data-oid="r90ackn">
                    {stats.freeUsage.total - stats.freeUsage.used} of{" "}
                    {stats.freeUsage.total}
                  </Text>
                </Box>
                <ProgressBar
                  progress={calculatePercentage(
                    stats.freeUsage.total - stats.freeUsage.used,
                    stats.freeUsage.total,
                  )}
                  sx={{
                    bg: "border.default",
                    "> div": {
                      bg: "success.emphasis",
                    },
                  }}
                  data-oid="mi4t7v_"
                />

                <Text
                  sx={{
                    fontSize: 1,
                    color: "fg.muted",
                    mt: 1,
                  }}
                  data-oid="hl8ow:1"
                >
                  {stats.freeUsage.used} usages consumed
                </Text>
              </Box>

              {/* Tokens */}
              <Box sx={{ mb: 3 }} data-oid="vz:e_r7">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mb: 1,
                  }}
                  data-oid="eei_x0v"
                >
                  <Text sx={{ fontSize: 1 }} data-oid="5.z9xgl">
                    Tokens
                  </Text>
                  <Text sx={{ fontSize: 1 }} data-oid="nv26vuf">
                    {stats.tokens.available} of{" "}
                    {stats.tokens.used + stats.tokens.available}
                  </Text>
                </Box>
                <ProgressBar
                  progress={calculatePercentage(
                    stats.tokens.available,
                    stats.tokens.used + stats.tokens.available,
                  )}
                  sx={{
                    bg: "border.default",
                    "> div": {
                      bg: "accent.emphasis",
                    },
                  }}
                  data-oid="frul7g-"
                />

                <Text
                  sx={{
                    fontSize: 1,
                    color: "fg.muted",
                    mt: 1,
                  }}
                  data-oid="dmrf-qg"
                >
                  {stats.tokens.used} tokens consumed
                </Text>
              </Box>
            </Box>
            <Box
              sx={{ mt: "auto", display: "flex", justifyContent: "flex-end" }}
              data-oid="i92xcfg"
            >
              <Button
                onClick={() => navigate("/dashboard/usage")}
                variant="default"
                trailingVisual={ArrowRightIcon}
                data-oid="retr2h3"
              >
                View Details
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </DashboardLayout>
  );
}
