import { Box, Text, Button, Tooltip } from "@primer/react";
import { formatDistanceToNow } from "date-fns";
import { Timestamp } from "firebase/firestore";
import { replaceVariablesWithValues } from "../utils/templateUtils.ts";
import { TrashIcon, CopyIcon } from "@primer/octicons-react";

interface TemplateGridCardProps {
  template: {
    id: string;
    name: string;
    html: string;
    data_json?: string;
    created_at: Timestamp;
    updated_at: Timestamp | null;
  };
  onClick: () => void;
  onDelete: (e: React.MouseEvent, id: string) => void;
  onCopyId: (e: React.MouseEvent, id: string) => void;
}

export function TemplateGridCard({
  template,
  onClick,
  onDelete,
  onCopyId,
}: TemplateGridCardProps) {
  // Process HTML content with variables if data_json exists
  const processedHtml = template.data_json
    ? replaceVariablesWithValues(template.html, template.data_json)
    : template.html;

  // Create preview HTML with necessary styles
  const previewHtml = `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
          }
          html, body {
            width: 100%;
            height: 100%;
            overflow: hidden;
            user-select: none;
            pointer-events: none;
            background: white;
          }
          body {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(0.3);
            width: 400%;
            height: 400%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        </style>
      </head>
      <body>
        <div class="content">
          ${processedHtml}
        </div>
      </body>
    </html>
  `;

  return (
    <Box
      onClick={onClick}
      sx={{
        position: "relative",
        border: "1px solid",
        borderColor: "border.default",
        borderRadius: 2,
        overflow: "hidden",
        cursor: "pointer",
        transition: "all 0.2s ease",
        "&:hover": {
          borderColor: "accent.emphasis",
          transform: "translateY(-2px)",
          boxShadow: "shadow.medium",
        },
      }}
      data-oid="wwuo121"
    >
      {/* Preview iframe */}
      <Box
        sx={{
          height: "200px",
          borderBottom: "1px solid",
          borderColor: "border.default",
          bg: "white",
          overflow: "hidden",
          position: "relative",
          padding: 0,
        }}
        data-oid="z._35_k"
      >
        <iframe
          srcDoc={previewHtml}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "none",
            pointerEvents: "none",
            padding: 0,
            margin: 0,
          }}
          title={`Preview of ${template.name}`}
          data-oid=".7r:niq"
        />
      </Box>

      {/* Template info with copy and delete buttons */}
      <Box sx={{ p: 3, position: "relative" }} data-oid="fyj3p0a">
        <Box sx={{ pr: 7 }} data-oid="0x3ixz8">
          <Text
            as="h3"
            sx={{
              fontSize: 2,
              fontWeight: "bold",
              mb: 1,
              color: "fg.default",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            data-oid="szdtd9m"
          >
            {template.name}
          </Text>
          <Text
            sx={{
              fontSize: 0,
              color: "fg.muted",
            }}
            data-oid="5pc_t::"
          >
            {(() => {
              if (template.updated_at && template.updated_at.toDate) {
                return `Updated ${formatDistanceToNow(template.updated_at.toDate())} ago`;
              }
              if (template.created_at && template.created_at.toDate) {
                return `Created ${formatDistanceToNow(template.created_at.toDate())} ago`;
              }
              return "-";
            })()}
          </Text>
        </Box>

        {/* Copy and Delete buttons */}
        <Box
          sx={{
            position: "absolute",
            bottom: "12px",
            right: "12px",
            display: "flex",
            gap: 1,
          }}
          data-oid="ztq57zd"
        >
          <Tooltip text="Copy template ID" direction="n" data-oid=":3ey6p_">
            <Button
              variant="invisible"
              size="small"
              sx={{
                padding: "4px",
                "&:hover": {
                  bg: "accent.subtle",
                },
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(template.id);
                if (onCopyId) {
                  onCopyId(e, template.id);
                }
              }}
              leadingVisual={CopyIcon}
              data-oid="k1e0xjp"
            />
          </Tooltip>

          {/* Existing delete button */}
          <Tooltip text="Delete template" direction="n" data-oid="ve0ewor">
            <Button
              variant="invisible"
              size="small"
              sx={{
                color: "danger.fg",
                padding: "4px",
                "&:hover": {
                  bg: "danger.subtle",
                },
              }}
              onClick={(e) => onDelete(e, template.id)}
              leadingVisual={TrashIcon}
              data-oid="nceg1yx"
            />
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
}
