import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, BaseStyles, SSRProvider } from "@primer/react";
import { AuthProvider } from "./contexts/AuthContext";
import { ProtectedRoute } from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ApiKeys from "./pages/ApiKeys";
import TemplateEditor from "./pages/TemplateEditor";
import Templates from "./pages/Templates";
import Settings from "./pages/Settings";
import ApiDocumentation from "./pages/ApiDocumentation";
import ApiUsage from "./pages/ApiUsage";
import TemplatePrompt from "./pages/TemplatePrompt";
import Usage from "./pages/Usage";
import VisualEditor from "./pages/VisualEditor";

function App() {
  return (
    <SSRProvider data-oid=".7o69.a">
      <ThemeProvider colorMode="day" preventSSRMismatch data-oid="2b_zq3o">
        <BaseStyles data-oid="ygmqcm.">
          <Router data-oid="xf:bg9c">
            <AuthProvider data-oid="97he3pq">
              <Routes data-oid="5.1fo9a">
                <Route
                  path="/login"
                  element={<Login data-oid="gibbhuh" />}
                  data-oid="edah_hl"
                />

                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute data-oid="izfmudf">
                      <Dashboard data-oid="jhlw.pw" />
                    </ProtectedRoute>
                  }
                  data-oid="ipdr6c9"
                />

                <Route
                  path="/dashboard/usage"
                  element={
                    <ProtectedRoute data-oid="ai7tp95">
                      <Usage data-oid="zrervjo" />
                    </ProtectedRoute>
                  }
                  data-oid="d492zt."
                />

                <Route
                  path="/dashboard/api-keys"
                  element={
                    <ProtectedRoute data-oid="lu8muw0">
                      <ApiKeys data-oid="i8fh3k6" />
                    </ProtectedRoute>
                  }
                  data-oid="u2osgs0"
                />

                <Route
                  path="/dashboard/templates"
                  element={
                    <ProtectedRoute data-oid="6.7xz8-">
                      <Templates data-oid=".rue2a1" />
                    </ProtectedRoute>
                  }
                  data-oid="olfjse9"
                />

                <Route
                  path="/dashboard/templates/new"
                  element={
                    <ProtectedRoute data-oid="n3.2x:t">
                      <TemplateEditor data-oid="cuaf1lh" />
                    </ProtectedRoute>
                  }
                  data-oid="6lyjn0a"
                />

                <Route
                  path="/dashboard/templates/visual"
                  element={
                    <ProtectedRoute data-oid=":v8o6vt">
                      <VisualEditor data-oid="lowo0ou" />
                    </ProtectedRoute>
                  }
                  data-oid="6bog04v"
                />

                <Route
                  path="/dashboard/templates/edit/:id"
                  element={
                    <ProtectedRoute data-oid="p158mu6">
                      <TemplateEditor data-oid="opls7l7" />
                    </ProtectedRoute>
                  }
                  data-oid="n-817pl"
                />

                <Route
                  path="/dashboard/settings"
                  element={
                    <ProtectedRoute data-oid="f:e.9ev">
                      <Settings data-oid="m-18iep" />
                    </ProtectedRoute>
                  }
                  data-oid="e_.wo7j"
                />

                <Route
                  path="/api-documentation"
                  element={
                    <ProtectedRoute data-oid="dcmouf_">
                      <ApiDocumentation data-oid="xm0psnr" />
                    </ProtectedRoute>
                  }
                  data-oid="a3o5gf2"
                />

                <Route
                  path="/dashboard/api-usage/:apiKeyId"
                  element={
                    <ProtectedRoute data-oid="kfe_fop">
                      <ApiUsage data-oid="79r0txr" />
                    </ProtectedRoute>
                  }
                  data-oid="p6ynzx3"
                />

                <Route
                  path="/dashboard/templates/prompt"
                  element={
                    <ProtectedRoute data-oid="z4v8a5z">
                      <TemplatePrompt data-oid="2k3:9jx" />
                    </ProtectedRoute>
                  }
                  data-oid="mlsvz8_"
                />

                <Route
                  path="/"
                  element={<Login data-oid="6:7u9tr" />}
                  data-oid="nop1lwb"
                />
              </Routes>
            </AuthProvider>
          </Router>
        </BaseStyles>
      </ThemeProvider>
    </SSRProvider>
  );
}

export default App;
